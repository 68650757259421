import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../store/authStore";

// Wrapper for routes accessible only to unauthenticated users
const UnauthenticatedRoute = ({ children }) => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

    // If user is authenticated, redirect to dashboard
    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    // Otherwise, render the children (unauthenticated component)
    return children;
};

export default UnauthenticatedRoute;
