import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Legend } from "recharts";

const data = [
    { location: 'United States', percentage: 51.1 },
    { location: 'Canada', percentage: 22.9 },
    { location: 'Mexico', percentage: 13.9 },
    { location: 'Other', percentage: 11.2 }
];

const colors = ['#287f71', '#eb862a', '#abbdd3', '#4f46e5'];

export function LocationTrafficChart() {
    return (
        <div style={{
            width: '95%',
            height: '300px',
            backgroundColor: '#fff',
            borderRadius: '12px',
            padding: '20px',
            boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
        }}>
            <h3 style={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#333',
                marginBottom: '20px'
            }}>Traffic by Location</h3>
            <ResponsiveContainer width="100%" height="85%">
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="percentage"
                        nameKey="location"
                        cx="25%"
                        cy="50%"
                        outerRadius={100}
                        innerRadius={80}
                        paddingAngle={2}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="middle"
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}
