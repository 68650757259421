import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../store/authStore";

// Wrapper for protected routes
const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const onboardingComplete = useAuthStore((state) => state.onboardingComplete);
    const onboardingVerified = useAuthStore((state) => state.onboardingVerified);
    const location = useLocation(); // To get the current path

    // get state from store
    const user = useAuthStore((state) => state.getState);


    console.log("user", user());
    // Check if the current path is /onboarding
    if (isAuthenticated && !onboardingComplete && !onboardingVerified && location.pathname === "/onboarding") {
        console.log('cond 1');
        return children; // Allow access to the onboarding page without redirecting
    }

    if (isAuthenticated && onboardingComplete && !onboardingVerified && location.pathname === "/onboarding-pending") {
        console.log('cond 2');
        return children; // Allow access to the onboarding page without redirecting
    }

    // If the user is not authenticated, redirect to sign-in
    if (!isAuthenticated) {
        return <Navigate to="/sign-in" />;
    } else if (isAuthenticated && !onboardingComplete) {
        return <Navigate to="/onboarding" />;
    } else if (isAuthenticated && onboardingComplete && !onboardingVerified) {
        return <Navigate to="/onboarding-pending" />;
    }

    // Otherwise, render the children (protected component)
    return children;
};

export default ProtectedRoute;
