import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',];

const data = months.map((month, index) => ({
    month,
    thisYear: Math.random() * 2000 + 1000,
    lastYear: Math.random() * 2000 + 800,
}));

export function UserTrendsChart() {
    return (
        <div style={{
            width: '100%',
            backgroundColor: '#fff',
            borderRadius: '12px',
            padding: '20px',
            boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
        }}>
            <h3 style={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#333',
            }}>Total Users</h3>
            <ResponsiveContainer width="100%" height="85%">
                <LineChart data={data}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis
                        dataKey="month"
                        axisLine={false}
                        tickLine={false}
                        stroke="#999"
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        stroke="#999"
                    />
                    <Tooltip />
                    <Line
                        type="monotone"
                        dataKey="thisYear"
                        stroke="#287f71"
                        strokeWidth={2}
                        dot={false}
                        name="This Year"
                    />
                    <Line
                        type="monotone"
                        dataKey="lastYear"
                        stroke="#eb862a"
                        strokeWidth={2}
                        dot={false}
                        name="Last Year"
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
