import React, { useState } from 'react';
import { BsApple, BsGoogle } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { apiCall } from '../backend/api/apiService';
import { authInstance } from '../backend/firebase/firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { useAuthStore } from '../store/authStore'; // Import Zustand store
import useAuthTokenStore from '../store/AuthTokenContext';

export default function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const setUser = useAuthStore((state) => state.setUser); // Zustand action to set user
    const navigate = useNavigate(); // To handle redirection
    const registerUser = useAuthTokenStore((state) => state.registerUser);
    const loginUser = useAuthTokenStore((state) => state.loginUser);

    const handleCreateAccount = async (e) => {
        e.preventDefault();
        try {
            // Check if passwords match
            if (password !== confirmPassword) {
                alert('Passwords do not match');
                return;
            }

            // Create Firebase user with email and password
            const userCredential = await createUserWithEmailAndPassword(authInstance, email, password);

            // Get the user object from the userCredential
            const user = userCredential.user;

            // send email verification
            await sendEmailVerification(user);

            // Prepare FormData to send to the backend
            const formData = new FormData();
            formData.append('uid', user.uid);
            formData.append('email', user.email);

            // Call backend API to create user entry in Postgres
            const response = await apiCall('/api/brands/create', 'POST', formData);
            console.log('User created and data sent to backend:', response);
            var response3;
            // Step 3: Fallback to Registration
            try {
                await registerUser({
                    username: email,
                    password: user.uid,
                    user_id: user.uid,
                });
                console.log("Registration successful, logging in...");

                // Immediately log in the newly registered user to get the token
                response3 = await loginUser({ username: email, password: user.uid });
                console.log("Post-registration Login Response", response3);
            } catch (registerError) {
                console.error("Registration failed:", registerError);
                throw new Error('User registration failed.');
            }

            // get brand_id from response
            const brand_id = response.user.id;
            // Save user in Zustand (set user as logged in)
            setUser({
                uid: user.uid,
                email: user.email,
                brand: response.user,
            });
            const response2 = await apiCall(`/api/brands/business-details/${brand_id}/intialize`, 'POST');
            console.log('Business details initialized:', response2);
            navigate('/onboarding');
        } catch (error) {
            console.error('Account creation failed:', error);
            alert('Account Creation Failed: ' + error.message);
        }
    };

    return (
        <div className='signin-container'>
            <div className='signin-left'>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url('https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?q=80&w=2094&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(30px)', // Added blur effect
                    borderRadius: '50px' // Match the container's border radius
                }}></div>
                <div style={{ position: 'relative', zIndex: 1 }}>
                    <h1 className='lynkd-logo'>LYNKD</h1>
                </div>
            </div>
            <div className='signin-right'>
                <div className="sign-in-container">
                    <h2 className="sign-in-title">Sign Up</h2>
                    <form onSubmit={handleCreateAccount}>
                        <label htmlFor="email" className="sign-in-label">Email</label>
                        <input
                            type="email"
                            placeholder="Email"
                            className="sign-in-input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label htmlFor="password" className="sign-in-label">Password</label>
                        <input
                            type="password"
                            placeholder="Password"
                            className="sign-in-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <label htmlFor="password" className="sign-in-label">Confirm Password</label>
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            className="sign-in-input"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <button type="submit" className="sign-in-button">
                            Continue
                        </button>
                    </form>
                    <div className="sign-in-divider">
                        <div className="sign-in-line"></div>
                        <span className="sign-in-or-text">or</span>
                        <div className="sign-in-line"></div>
                    </div>
                    {/* <div className="sign-in-social-buttons">
                        <button className="sign-in-social-button">
                            <span className="sign-in-social-icon"><BsGoogle size={30} /></span> Google
                        </button>
                        <button className="sign-in-social-button">
                            <span className="sign-in-social-icon"><BsApple size={30} /></span> Apple
                        </button>
                    </div> */}
                    <div className="sign-in-signup-text">
                        Already have an account?
                        <button className="sign-in-signup-button">
                            <Link to="/sign-in" style={{ color: 'white', textDecoration: 'none', width: '100%', display: 'block' }}>
                                Sign In
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
