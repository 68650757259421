import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { apiCall } from '../backend/api/apiService';
function EditProduct() {
    const { productID } = useParams();
    const user = useAuthStore(state => state.user);
    const brand_id = user.brand.id;
    const [allowAffiliations, setAllowAffiliations] = useState(true);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productTags, setProductTags] = useState('');
    const [productSKU, setProductSKU] = useState('');
    const [regularPrice, setRegularPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [affiliatePrice, setAffiliatePrice] = useState('');
    const [affiliateCommission, setAffiliateCommission] = useState('');
    const [affiliateRequirement, setAffiliateRequirement] = useState('');
    const [affiliationLink, setAffiliationLink] = useState('');
    const [category, setCategory] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [initialProductData, setInitialProductData] = useState({});

    const toggleAffiliations = () => {
        setAllowAffiliations(!allowAffiliations);
    };

    const [mainImage, setMainImage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [mainImageData, setMainImageData] = useState(null);
    const [galleryImagesData, setGalleryImagesData] = useState([]);

    const [categories, setCategories] = useState([]); // Holds the categories at each level
    const [selectedCategories, setSelectedCategories] = useState([]); // Holds selected category IDs at each level
    const [categoriesArray, setCategoriesArray] = useState([]); // Holds the selected category IDs in an array
    const [categoriesIDs, setCategoriesIDs] = useState([]); // Holds the selected category IDs in an array

    const fetchCategories = async (parentId) => {
        // Fetch subcategories based on the parent ID
        const response = await apiCall(`/api/products/categories/${parentId ? 'sub/' + parentId : 'root'}`);
        return response.categories;
    };

    useEffect(() => {
        // Fetch root categories when component mounts
        const fetchRootCategories = async () => {
            const rootCategories = await fetchCategories(null);
            setCategories([rootCategories]);
        };
        fetchRootCategories();
    }, []);

    // This useEffect will run when categoriesIDs changes
    useEffect(() => {
        // Initialize categories dropdowns based on the current product's categories
        const initializeCategories = async () => {
            const initialCategories = [];
            const newSelectedCategories = [...categoriesIDs]; // Preload selected category IDs

            for (let i = 0; i < newSelectedCategories.length; i++) {
                const parentId = newSelectedCategories[i - 1] || null;
                const subCategories = await fetchCategories(parentId);
                initialCategories.push(subCategories);
            }

            setCategories(initialCategories);
            setSelectedCategories(newSelectedCategories); // Set selected categories after fetching subcategories
        };

        // Only initialize if there are category IDs available
        if (categoriesIDs.length > 0) {
            initializeCategories();
        }
    }, [categoriesIDs]);

    const handleCategoryChange = async (index, categoryId) => {
        // Update the selected categories array at the current level
        const newSelectedCategories = [...selectedCategories];
        newSelectedCategories[index] = categoryId;
        setSelectedCategories(newSelectedCategories);

        // If there are further subcategories, fetch and add them as the next level
        const nextCategories = await fetchCategories(categoryId);
        if (nextCategories.length > 0) {
            const newCategories = [...categories];
            newCategories[index + 1] = nextCategories; // Set the next level categories
            setCategories(newCategories.slice(0, index + 2)); // Truncate deeper levels if any
        } else {
            // No further subcategories, so truncate categories array
            setCategories(categories.slice(0, index + 1));
        }
        console.log('Selected categories:', selectedCategories);

        // Add the modified fields
        handleFieldChange('category_id', categoryId);
    };


    const handleMainImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setMainImage(imageUrl);
            setMainImageData(file);
        }
    };

    const handleGalleryImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const newGalleryImages = [...galleryImages];
            newGalleryImages[index] = URL.createObjectURL(file);
            setGalleryImages(newGalleryImages);
            setGalleryImagesData([...galleryImagesData, file]);
        }
    };

    const navigate = useNavigate();

    const [modifiedFields, setModifiedFields] = useState(initialProductData);

    // Function to track changes to each field
    const handleFieldChange = (field, value) => {
        setModifiedFields((prevState) => ({
            ...prevState,
            [field]: value !== initialProductData[field] ? value : undefined,
        }));
    };

    const UpdateProductHandler = async () => {
        const data = new FormData();

        // Only append modified fields to FormData
        Object.entries(modifiedFields).forEach(([field, value]) => {
            if (value !== undefined) {
                if (field === 'image_gallery' && Array.isArray(value)) {
                    // Handle multiple gallery images
                    value.forEach((image) => data.append('image_gallery', image));
                } else {
                    data.append(field, value);
                }
            }
        });

        console.log('Modified fields:', modifiedFields);

        // Call API with only modified data
        const response = await apiCall(`/api/products/update/${productID}`, 'PUT', data);
        console.log('Product updated:', response);
        navigate('/shop');
    }

    const fetchProductDetails = async () => {
        try {
            const response = await apiCall(`/api/products/product/${productID}`, "GET");
            console.log(response);
            var productObjInitial = {}
            if (response && response.data) {
                const product = response.data;
                setProductName(product.name);
                setProductDescription(product.description);
                setProductTags(product.product_tags);
                setProductSKU(product.product_sku);
                setRegularPrice(product.regular_price);
                setSalePrice(product.sale_price);
                setAffiliatePrice(product.affiliate_price);
                setMainImage(product.main_image);
                setGalleryImages(product.image_gallery);
                setCategory(product.category_id);
                productObjInitial = {
                    name: product.name,
                    description: product.description,
                    regular_price: product.regular_price,
                    sale_price: product.sale_price,
                    affiliate_price: product.affiliate_price,
                    stock: '50', // or the actual stock value if provided
                    brand_id: product.brand_id,
                    category_id: product.category_id,
                    product_tags: product.product_tags,
                    product_sku: product.product_sku,
                    main_image: product.main_image,
                    image_gallery: product.image_gallery,
                };
            }

            if (brand_id !== response.data.brand_id) {
                navigate('/shop');
            }

            // Get Product Affiliations
            const affiliationsResponse = await apiCall(`/api/products/affiliations/${productID}`, "GET");
            if (affiliationsResponse && affiliationsResponse.data) {
                const affiliation = affiliationsResponse.data;
                console.log("AFF:", affiliation);
                setAllowAffiliations(true);
                setAffiliateCommission(affiliation.affiliation_commission_percentage);
                setAffiliateRequirement(affiliation.affiliation_minimum_requirement);
                setAffiliationLink(affiliation.affiliation_link);

                productObjInitial = {
                    ...productObjInitial,
                    affiliation_commission_percentage: affiliation.affiliation_commission_percentage,
                    affiliation_minimum_requirement: affiliation.affiliation_minimum_requirement,
                    affiliation_link: affiliation.affiliation_link,
                }
            }

            // Get the category array
            const categoryResponse = await apiCall(`/api/products/categories/array/${response.data.category_id}`, "GET");
            if (categoryResponse && categoryResponse.categoriesArray) {
                const category = categoryResponse.categoriesArray;
                console.log("CAT:", category);
                setCategoriesArray(category);

                const categoryIDs = categoryResponse.categoriesIDArray;
                console.log("CAT ID:", categoryIDs);
                setCategoriesIDs(categoryIDs);
            }
        } catch (error) {
            console.error("Error fetching product details:", error);
        }
    };

    useEffect(() => {
        // Wrapping the async function inside an inner function
        const fetchData = async () => {
            if (productID) {
                await fetchProductDetails();
            }
        };

        fetchData();
    }, [productID]); // Only re-run when productID changes



    return (
        <div className='add-product'>
            <div className="add-product-header">
                <div>
                    <h1>Edit Product</h1>
                    <p style={{ marginTop: -20 }}>Edit product for your customers</p>
                </div>
                <div style={{ display: 'flex' }}>
                    <button className="save-product-button" onClick={UpdateProductHandler}>Update Product</button>
                </div>
            </div>
            <div className="product-form">
                <div className="form-section basic-info">
                    <h3>Basic Information</h3>
                    <label htmlFor="product-name">Product Name</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Product Name"
                            value={productName}
                            onChange={(e) => {
                                setProductName(e.target.value);
                                handleFieldChange('name', e.target.value);
                            }}
                        />
                    </div>
                    <label htmlFor="product-description">Product Description</label>
                    <div className='product-textarea'>
                        <textarea
                            placeholder="Product Description"
                            value={productDescription}
                            onChange={(e) => {
                                setProductDescription(e.target.value);
                                handleFieldChange('description', e.target.value);
                            }}
                        ></textarea>
                    </div>
                    <label htmlFor="product-tags">Product Tags</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Product Tags"
                            value={productTags}
                            onChange={(e) => {
                                setProductTags(e.target.value);
                                handleFieldChange('product_tags', e.target.value);
                            }}
                        />
                    </div>
                    <label htmlFor="product-sku">Product SKU</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Product SKU"
                            value={productSKU}
                            onChange={(e) => {
                                setProductSKU(e.target.value);
                                handleFieldChange('product_sku', e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="form-section affiliations">
                    <h3>Affiliations</h3>
                    <div style={{ display: 'flex', gap: 10, margin: "10px 0px" }}>
                        <span>Allow Affiliations</span>
                        <label className="switch">
                            <input type="checkbox" checked={allowAffiliations} onChange={toggleAffiliations} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {allowAffiliations && <>
                        <label htmlFor="affiliate-commission">Affiliate Commission</label>
                        <div className='product-input'>
                            <input
                                type="text"
                                placeholder="Affiliate Commission %"
                                value={affiliateCommission}
                                onChange={(e) => {
                                    setAffiliateCommission(e.target.value);
                                    handleFieldChange('affiliation_commission_percentage', e.target.value);
                                }}
                                disabled={!allowAffiliations}
                            />
                        </div>
                        <label htmlFor="affiliate-requirement">Affiliate Requirement</label>
                        <div className='product-input'>
                            <input
                                type="text"
                                placeholder="Creator Minimum Follower Requirement"
                                value={affiliateRequirement}
                                onChange={(e) => {
                                    setAffiliateRequirement(e.target.value);
                                    handleFieldChange('affiliation_minimum_requirement', e.target.value);
                                }}
                                disabled={!allowAffiliations}
                            />
                        </div>
                        <label htmlFor="affiliation-link">Affiliation Link</label>
                        <div className='product-input'>
                            <input
                                type="text"
                                placeholder="Affiliation Link"
                                value={affiliationLink}
                                onChange={(e) => {
                                    setAffiliationLink(e.target.value);
                                    handleFieldChange('affiliation_link', e.target.value);
                                }}
                                disabled={!allowAffiliations}
                            />
                        </div>
                    </>
                    }
                </div>
                <div className="form-section product-images">
                    <h3>Product Images</h3>
                    <div className="image-upload">
                        <div className="main-image">
                            <span style={{ width: '100%' }}>Main Image</span>
                            <div className="image-placeholder-main">
                                {mainImage ? (
                                    <img src={mainImage} alt="Main" className="uploaded-image" />
                                ) : (
                                    <>
                                        <label htmlFor="main-image-upload" className="image-upload-label" style={{ width: '100%', height: '100%' }}>
                                            <BiPlus size={40} />
                                        </label>
                                        <input
                                            type="file"
                                            id="main-image-upload"
                                            style={{ display: 'none' }}
                                            onChange={handleMainImageChange}
                                            accept="image/*"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="gallery-images">
                            <span style={{ width: '100%' }}>Product Gallery Images</span>
                            {[...Array(4)].map((_, i) => (
                                <div key={i} className="image-placeholder">
                                    {galleryImages[i] ? (
                                        <img src={galleryImages[i]} alt={`Gallery ${i}`} className="uploaded-image" />
                                    ) : (
                                        <>
                                            <label htmlFor={`gallery-image-upload-${i}`} className="image-upload-label" style={{ width: '100%', height: '100%' }}>
                                                <BiPlus size={20} />
                                            </label>
                                            <input
                                                type="file"
                                                id={`gallery-image-upload-${i}`}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleGalleryImageChange(i, e)}
                                                accept="image/*"
                                            />
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <br />
                    <label htmlFor="image-urls">Image URLs</label>
                    <div className='product-textarea image-urls-area'>
                        <textarea placeholder="Image URLs (separated by comma)"></textarea>
                    </div>
                </div>
                <div className="form-section price-section">
                    <h3>Price</h3>
                    <label htmlFor="regular-price">Regular Price</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Regular Price"
                            value={regularPrice}
                            onChange={(e) => {
                                setRegularPrice(e.target.value);
                                handleFieldChange('regular_price', e.target.value);
                            }}
                        />
                    </div>
                    <label htmlFor="sale-price">Discount Price</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Discount Price"
                            value={salePrice}
                            onChange={(e) => {
                                setSalePrice(e.target.value);
                                handleFieldChange('sale_price', e.target.value);
                            }}
                        />
                    </div>
                    <label htmlFor="affiliate-price">Affiliate Price</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Affiliate Price"
                            value={affiliatePrice}
                            onChange={(e) => {
                                setAffiliatePrice(e.target.value);
                                handleFieldChange('affiliate_price', e.target.value);
                            }}
                        />
                    </div>
                    <label htmlFor="discount-expiry-date">Discount Expiry Date</label>
                    <div className='product-input'>
                        <input type="date" placeholder="Discount Expiry Date" />
                    </div>
                </div>
            </div>

            <div className="form-section">
                <h3>Category</h3>
                {categories.map((catLevel, index) => (
                    <div key={index} className="category-section">
                        <label htmlFor={`category-level-${index}`} style={{
                            display: 'block',
                            marginTop: 20,
                            fontWeight: 'bold'
                        }}>
                            Category Level {index + 1}
                        </label>
                        <br />
                        <select
                            className="add-category-select"
                            value={selectedCategories[index] || ''}
                            onChange={(e) => handleCategoryChange(index, e.target.value)}
                            style={{ width: '20%', marginLeft: 20 }}
                        >
                            <option value="">Select Category</option>
                            {catLevel.map(category => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default EditProduct