import React from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Cell, ResponsiveContainer } from "recharts";

const data = [
    { device: 'Desktop', visits: 1500 },
    { device: 'Mobile', visits: 2000 },
    { device: 'Tablet', visits: 1000 },
    { device: 'Windows', visits: 1800 },
    { device: 'Android', visits: 1200 },
    { device: 'PC', visits: 1600 },
    { device: 'Apple', visits: 1000 },
    { device: 'Other', visits: 1600 },
];

const colors = ['#287f71', '#eb862a', '#abbdd3'];

export function DeviceTrafficChart() {
    return (
        <div style={{
            width: '95%',
            height: '300px',
            backgroundColor: '#fff',
            borderRadius: '12px',
            padding: '20px',
            boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
        }}>
            <h3 style={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#333',
                marginBottom: '20px'
            }}>Traffic by Device</h3>
            <ResponsiveContainer width="100%" height="85%">
                <BarChart data={data}>
                    <CartesianGrid stroke="#f5f5f5" vertical={false} />
                    <XAxis
                        dataKey="device"
                        axisLine={false}
                        tickLine={false}
                        stroke="#999"
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        stroke="#999"
                    />
                    <Tooltip />
                    <Bar
                        dataKey="visits"
                        radius={[4, 4, 4, 4]}
                        barSize={20}  // Added this line to reduce bar width
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

