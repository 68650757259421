import { useEffect } from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getApp } from 'firebase/app';
import app from './firebase';

// Function to request notification permissions and handle FCM setup in a React.js web app
export const initializeFCM = () => {
    useEffect(() => {
        const messaging = getMessaging(app);

        // Request permission to send notifications
        const requestPermission = async () => {
            try {
                // Request permission for push notifications in the browser
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    return true;
                } else {
                    console.log('Notification permission denied.');
                    alert('Please enable notifications to receive updates.');
                    return false;
                }
            } catch (error) {
                console.error('Error requesting notification permission:', error);
                return false;
            }
        };

        // Get FCM token after permission is granted
        const getFCMToken = async () => {
            try {
                const fcmToken = await getToken(messaging, {
                    vapidKey: process.env.REACT_APP_WEB_PUSH_KEY // Provide your VAPID key here
                });

                if (fcmToken) {
                    console.log('FCM Token:', fcmToken);
                    // Store the token or send it to the backend for further use
                } else {
                    console.log('No FCM token received');
                }
            } catch (error) {
                console.error('Error getting FCM token:', error);
            }
        };

        // Handle foreground messages
        const handleForegroundMessages = () => {
            onMessage(messaging, (payload) => {
                console.log('Message received. ', payload);
                // Display notification or handle the message data
                alert(`Notification received: ${payload.notification.title}`);
            });
        };

        // Function to initialize FCM after permissions are handled
        const setupFCM = async () => {
            const permissionGranted = await requestPermission();
            if (permissionGranted) {
                await getFCMToken();
                handleForegroundMessages();
            }
        };

        // Initialize FCM
        setupFCM();
    }, []);
};
