import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import "./App.css";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUpDetails from "./pages/SignUpDetails";
import OnboardingWaiting from "./pages/OnboardingWaiting";
import Shop from "./pages/Shop";
import AddProduct from "./pages/AddProduct";
import ChatMessages from "./pages/ChatMessages";
import Orders from "./pages/Orders";
import ContactSupport from "./pages/ContactSupport";
import Ads from "./pages/Ads";
import CreateAdCampaign from "./pages/CreateAdCampaign";
import BrandAmbassadors from "./pages/BrandAmbassadors";
import BrandAmbassadorsRequests from "./pages/BrandAmbassadorsRequests";
import { initializeFCM } from "./backend/firebase/FCM";
import ProtectedRoute from "./routes/ProtectedRoute"; // Import ProtectedRoute
import UnauthenticatedRoute from "./routes/UnauthenticatedRoute"; // Import UnauthenticatedRoute
import ProductDetail from "./pages/ProductDetail";
import EditProduct from "./pages/EditProduct";

function AppRoutes() {
  // const activePage = window.location.pathname;
  const location = useLocation();

  useEffect(() => {
    const activePage = location.pathname;
    if (activePage === '/sign-in' || activePage === '/sign-up' || activePage === '/onboarding' || activePage === '/onboarding-pending') {
      document.querySelector('.sidebar').style.display = 'none';
    } else {
      document.querySelector('.sidebar').style.display = 'flex';
    }
  }, [location]);
  return (
    <div className="app">
      {/* if route is not login then show side bar */}
      {/* {
          (activePage !== '/sign-in' && activePage !== '/sign-up') && <Sidebar />
        } */}
      <Sidebar />
      <Routes>
        <Route path="/" element={<Navigate to="/sign-in" />} />

        {/* Unauthenticated routes */}
        <Route
          path="/sign-in"
          element={
            <UnauthenticatedRoute>
              <SignIn />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/sign-up"
          element={
            <UnauthenticatedRoute>
              <SignUp />
            </UnauthenticatedRoute>
          }
        />

        {/* Onboarding routes */}
        <Route
          path="/onboarding"
          element={
            <ProtectedRoute>
              <SignUpDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/onboarding-pending"
          element={
            <ProtectedRoute>
              <OnboardingWaiting />
            </ProtectedRoute>
          }
        />

        {/* Authenticated routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shop"
          element={
            <ProtectedRoute>
              <Shop />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shop/add-product"
          element={
            <ProtectedRoute>
              <AddProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shop/product/:productID"
          element={
            <ProtectedRoute>
              <ProductDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shop/edit-product/:productID"
          element={
            <ProtectedRoute>
              <EditProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/messages"
          element={
            <ProtectedRoute>
              <ChatMessages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ads-management"
          element={
            <ProtectedRoute>
              <Ads />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ads-management/create-campaign"
          element={
            <ProtectedRoute>
              <CreateAdCampaign />
            </ProtectedRoute>
          }
        />
        <Route
          path="/brand-ambassadors"
          element={
            <ProtectedRoute>
              <BrandAmbassadors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/brand-ambassadors/requests"
          element={
            <ProtectedRoute>
              <BrandAmbassadorsRequests />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact-support"
          element={
            <ProtectedRoute>
              <ContactSupport />
            </ProtectedRoute>
          }
        />

        {/* catch all route */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </div>
  );
}

export default function App() {
  // initializeFCM();
  return (
    <Router>
      <AppRoutes />
    </Router>
  )
}
;