import React, { useEffect, useState } from 'react'
// import GlitchText from '../components/GlitchText'
// import GlitchTextAnimated from '../components/GlitchTextAnimated'
// import { fontSize, height } from '@mui/system'
import { BsFacebook, BsInstagram, BsPersonBadge, BsThreads, BsTwitterX } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { BiChevronLeft, BiChevronRight, BiEnvelope, BiFlag, BiGlobe, BiMoney, BiPhone, BiSave, BiUpload } from 'react-icons/bi'
import { PiPhone } from 'react-icons/pi'
import { GrDocument, GrDocumentStore } from 'react-icons/gr'
import { MdAccountCircle, MdBadge, MdBrandingWatermark, MdDateRange, MdNumbers, MdOutlineEmail } from 'react-icons/md'
import { RiPriceTag2Line } from 'react-icons/ri'
import StepIndicator from '../components/StepIndicator'
import { TfiLocationPin } from 'react-icons/tfi'
import { FaAddressBook, FaCity } from 'react-icons/fa'
import { CgFlagAlt, CgWorkAlt } from 'react-icons/cg'
import { useAuthStore } from '../store/authStore'
import { apiCall } from '../backend/api/apiService'
import { authInstance } from '../backend/firebase/firebase'

export default function SignUpDetails() {
    const [userEmail, setUserEmail] = useState("");
    const [brandName, setBrandName] = useState("");
    const [brandTagline, setBrandTagline] = useState("");
    const [emailStatus, setEmailStatus] = useState("Not Verified");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [brandStoreLink, setBrandStoreLink] = useState("");
    const [brandSocials, setBrandSocials] = useState({ instagram: "", facebook: "", twitter: "", threads: "" });
    const [documents, setDocuments] = useState([{ title: "", file: null }]);
    const [taxId, setTaxId] = useState('');
    const [agreements, setAgreements] = useState({ declaration: false, policy: false });
    const [currentStep, setCurrentStep] = useState(0);
    const [licenseType, setLicenseType] = useState("");
    const [corporateOfficeLocation, setCorporateOfficeLocation] = useState("");
    const [licenseNumber, setLicenseNumber] = useState("");
    const [licenseStartDate, setLicenseStartDate] = useState("");
    const [category, setCategory] = useState("");
    const [supplierName, setSupplierName] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [address, setAddress] = useState("");
    const [countryOfOrigin, setCountryOfOrigin] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [licenseCopy, setLicenseCopy] = useState(null);
    const [authorizedContact, setAuthorizedContact] = useState({ name: "", designation: "", email: "", contact: "" });
    const [inventoryUpload, setInventoryUpload] = useState(null);
    const [finalDeclaration, setFinalDeclaration] = useState(false);
    const [uploadedLicenseCopy, setUploadedLicenseCopy] = useState("");
    const [uploadedInventoryUpload, setUploadedInventoryUpload] = useState("");


    // Get current user
    const user = useAuthStore((state) => state.user);
    const setUser = useAuthStore((state) => state.setUser);
    // const logout = useAuthStore((state) => state.logout);
    // // call logout
    // logout();

    const saveDraftDetails = async (e) => {
        e.preventDefault();
        console.log(user)
        const brand_id = user.brand.id;
        const formData = new FormData();
        var response;
        if (currentStep === 0) {
            console.log("Saving draft for step 1");
            formData.append('license_type', licenseType);
            formData.append('corporate_office_location', corporateOfficeLocation);
            formData.append('license_number', licenseNumber);
            formData.append('license_start_date', new Date(licenseStartDate).toISOString().split('T')[0]);
            formData.append('category', category);
            console.log('Form Data:', formData);
            response = await apiCall(`/api/brands/business-details/${brand_id}/basic-info`, 'PUT', formData);
            console.log('Response:', response);
        } else if (currentStep === 1) {
            console.log("Saving draft for step 2");
            formData.append('supplier_name', supplierName);
            formData.append('company_email_address', companyEmail);
            formData.append('company_phone_number', companyPhone);
            formData.append('address', address);
            formData.append('country_of_origin', countryOfOrigin);
            formData.append('country', country);
            formData.append('city', city);
            formData.append('authorized_contact_name', authorizedContact.name);
            formData.append('authorized_contact_designation', authorizedContact.designation);
            formData.append('authorized_contact_email', authorizedContact.email);
            formData.append('authorized_contact_phone', authorizedContact.contact);
            if (licenseCopy) formData.append('license_copy', licenseCopy);
            console.log('Form Data:', formData);
            response = await apiCall(`/api/brands/business-details/${brand_id}/contact-info`, 'PUT', formData);
        } else if (currentStep === 2) {
            console.log("Saving draft for step 3");
            formData.append('website_link', brandStoreLink);
            if (inventoryUpload) formData.append('inventory_upload', inventoryUpload);
            response = await apiCall(`/api/brands/business-details/${brand_id}/shop-info`, 'PUT', formData);
        } else if (currentStep === 3) {
            console.log("Saving draft for step 4");
            formData.append('brand_name', brandName);
            formData.append('brand_tagline', brandTagline);
            formData.append('social_links', JSON.stringify(brandSocials));
            formData.append('tax_id', taxId);
            if (uploadImageBlob) formData.append('brand_logo', uploadImageBlob);
            console.log('Form Data:', formData.getAll('brand_logo'));
            response = await apiCall(`/api/brands/business-details/${brand_id}/brand-account-details`, 'PUT', formData);
        }

        if (currentStep < 4) {
            setCurrentStep((prev) => Math.min(prev + 1, 4));
        }
    };
    const handleEmailVerification = () => {
        // Check if authInstance is initialized and currentUser exists
        if (authInstance && authInstance.currentUser) {
            const isVerified = authInstance.currentUser.emailVerified;

            if (isVerified) {
                setEmailStatus("Verified");
            } else {
                setEmailStatus("Not Verified");
            }

            if (!isVerified && currentStep === 4) {
                // Send email verification
                authInstance.currentUser.sendEmailVerification()
                    .then(() => {
                        alert('Verification email sent! Please check your inbox.');
                    })
                    .catch((error) => {
                        console.error("Error sending email verification:", error);
                        alert('Error sending verification email. Please try again.');
                    });
            }
        } else {
            console.error("Auth instance is not available or user is not logged in.");
        }
    };

    const handleAddDocument = () => {
        setDocuments([...documents, { title: "", file: null }]);
    };

    const handleRemoveDocument = (index) => {
        const newDocuments = documents.filter((_, i) => i !== index);
        setDocuments(newDocuments);
    };

    const handleDocumentChange = (index, field, value) => {
        const newDocuments = [...documents];
        newDocuments[index][field] = value;
        setDocuments(newDocuments);
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle submission - validation and API calls here
        console.log("Form submitted");

        const brand_id = user.brand.id;
        const response = await apiCall(`/api/brands/business-details/${brand_id}/final-submit`, 'PUT');
        console.log('Response:', response);
        console.log('Form submitted');
        setUser({ ...user, onboardingComplete: true, onboardingVerified: false });

        navigate('/onboarding-pending');
    };

    const [image, setImage] = useState(null);
    const [uploadImageBlob, setUploadImageBlob] = useState(null);
    // Function to handle the change of the file input
    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUploadImageBlob(file);
            const imageURL = URL.createObjectURL(file); // Create an object URL for the image preview
            setImage(imageURL);
        }
    };

    const handleClick = () => {
        // Programmatically trigger the file input when the grey square (or image preview) is clicked
        document.getElementById("image-upload").click();
    };

    // const logout = useAuthStore((state) => state.logout);
    // call logout
    // logout();


    useEffect(() => {
        console.log("phone number:", companyPhone);
    }, [companyPhone]);

    useEffect(() => {
        const fetchBusinessDetails = async () => {
            try {
                const brand_id = user.brand.id;
                const response = await apiCall(`/api/brands/business-details/${brand_id}`, 'GET');
                console.log('Business details fetched:', response.brand[0]);
                setUserEmail(user.email);
                const brandDetails = response.brand[0].brand_business_details[0]
                const shopDetails = response.brand[0];
                console.log('Brand Details:', brandDetails);
                setUserEmail(user.email);
                setLicenseType(brandDetails.license_type);
                setCorporateOfficeLocation(brandDetails.corporate_office_location);
                setLicenseNumber(brandDetails.license_number);
                setLicenseStartDate(brandDetails.license_start_date);
                setCategory(brandDetails.category);
                setSupplierName(brandDetails.supplier_name);
                setCompanyEmail(brandDetails.company_email_address);
                setCompanyPhone(brandDetails.company_phone_number);
                setAddress(brandDetails.address);
                setCountryOfOrigin(brandDetails.country_of_origin);
                setCountry(brandDetails.country);
                setCity(brandDetails.city);
                setAuthorizedContact({
                    name: brandDetails.authorized_contact_name,
                    designation: brandDetails.authorized_contact_designation,
                    email: brandDetails.authorized_contact_email,
                    contact: brandDetails.authorized_contact_phone,
                });
                if (brandDetails.license_copy_url) setUploadedLicenseCopy(brandDetails.license_copy_url);
                if (brandDetails.inventory_import_url) setUploadedInventoryUpload(brandDetails.inventory_import_url);
                setBrandStoreLink(brandDetails.website_link);

                setBrandName(shopDetails.brand_name);
                setBrandTagline(shopDetails.bio);
                setImage(shopDetails.brandLogoURL);
                // parse social links
                try {
                    const socialLinks = JSON.parse(brandDetails.social_links);
                    setBrandSocials({
                        instagram: socialLinks.instagram,
                        facebook: socialLinks.facebook,
                        twitter: socialLinks.twitter,
                        threads: socialLinks.threads,
                    });
                } catch (error) {
                    console.error('Error parsing social links:', error);
                }
                setTaxId(brandDetails.tax_id);
            } catch (error) {
                console.error('Error fetching business details:', error);
            }
        };
        console.log(companyPhone)
        if (user) {
            fetchBusinessDetails();
        }
    }, [user]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleEmailVerification(); // Call the function after a delay
        }, 3000); // Delay in milliseconds (3000 ms = 3 seconds)

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []); // Empty dependency array means it runs once on mount


    // console.log('User:', user);


    return (
        <div className='know-more-container'>
            <div className='know-more-left'>
                <div className='know-more-left-glitch-container'>
                    <h1 className='lynkd-logo-smaller' >
                        LYNKD
                    </h1>
                </div>
            </div>
            <div className='know-more-right' style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', right: 10 }}>
                    <StepIndicator currentStep={currentStep} />
                    {/* <button className='save-draft-button' onClick={() => saveDraftDetails()}>
                        <BiSave size={25} />
                        Save Draft
                    </button> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 0))} className='step-previous'>
                            <BiChevronLeft size={25} />
                            Previous
                        </button>
                        <button onClick={() => setCurrentStep((prev) => Math.min(prev + 1, 4))} className='step-next'>
                            Next
                            <BiChevronRight size={25} />
                        </button>
                    </div>
                    {/* save draft */}
                </div>
                {/* Step Components Array */}
                {
                    [
                        <BasicInformation key={0}
                            licenseType={licenseType}
                            setLicenseType={setLicenseType}
                            corporateOfficeLocation={corporateOfficeLocation}
                            setCorporateOfficeLocation={setCorporateOfficeLocation}
                            licenseNumber={licenseNumber}
                            setLicenseNumber={setLicenseNumber}
                            licenseStartDate={licenseStartDate}
                            setLicenseStartDate={setLicenseStartDate}
                            category={category}
                            setCategory={setCategory}
                            handleSubmit={handleSubmit}
                            saveDraftDetails={saveDraftDetails}
                        />,
                        <ContactDetails key={1}
                            supplierName={supplierName}
                            setSupplierName={setSupplierName}
                            companyEmail={companyEmail}
                            setCompanyEmail={setCompanyEmail}
                            companyPhone={companyPhone}
                            setCompanyPhone={setCompanyPhone}
                            address={address}
                            setAddress={setAddress}
                            countryOfOrigin={countryOfOrigin}
                            setCountryOfOrigin={setCountryOfOrigin}
                            country={country}
                            setCountry={setCountry}
                            city={city}
                            setCity={setCity}
                            licenseCopy={licenseCopy}
                            setLicenseCopy={setLicenseCopy}
                            authorizedContact={authorizedContact}
                            setAuthorizedContact={setAuthorizedContact}
                            agreements={agreements}
                            setAgreements={setAgreements}
                            handleSubmit={handleSubmit}
                            saveDraftDetails={saveDraftDetails}
                        />,
                        <ShopDetails key={2}
                            brandStoreLink={brandStoreLink}
                            setBrandStoreLink={setBrandStoreLink}
                            setInventoryUpload={setInventoryUpload}
                            handleSubmit={handleSubmit}
                            saveDraftDetails={saveDraftDetails}
                        />,
                        <BrandAccountDetails key={3}
                            brandName={brandName}
                            setBrandName={setBrandName}
                            brandTagline={brandTagline}
                            setBrandTagline={setBrandTagline}
                            companyEmail={companyEmail}
                            setCompanyEmail={setCompanyEmail}
                            companyPhone={companyPhone}
                            setCompanyPhone={setCompanyPhone}
                            brandStoreLink={brandStoreLink}
                            setBrandStoreLink={setBrandStoreLink}
                            brandSocials={brandSocials}
                            setBrandSocials={setBrandSocials}
                            image={image}
                            setImage={setImage}
                            handleChange={handleChange}
                            handleClick={handleClick}
                            emailStatus={emailStatus}
                            handleEmailVerification={handleEmailVerification}
                            taxId={taxId}
                            setTaxId={setTaxId}
                            agreements={agreements}
                            setAgreements={setAgreements}
                            handleSubmit={handleSubmit}
                            userEmail={userEmail}
                            setUserEmail={setUserEmail}
                            saveDraftDetails={saveDraftDetails}
                        />,
                        <PreviewDetails
                            key={4}
                            brandName={brandName}
                            brandTagline={brandTagline}
                            emailStatus={emailStatus}
                            phoneNumber={phoneNumber}
                            brandStoreLink={brandStoreLink}
                            brandSocials={brandSocials}
                            documents={documents}
                            taxId={taxId}
                            agreements={agreements}
                            currentStep={currentStep}
                            licenseType={licenseType}
                            corporateOfficeLocation={corporateOfficeLocation}
                            licenseNumber={licenseNumber}
                            licenseStartDate={licenseStartDate}
                            category={category}
                            supplierName={supplierName}
                            registeredEmail={userEmail}
                            companyEmail={companyEmail}
                            companyPhone={companyPhone}
                            address={address}
                            countryOfOrigin={countryOfOrigin}
                            country={country}
                            city={city}
                            licenseCopy={uploadedLicenseCopy}
                            authorizedContact={authorizedContact}
                            inventoryUpload={uploadedInventoryUpload}
                            finalDeclaration={finalDeclaration}
                            setFinalDeclaration={setFinalDeclaration}
                            finalSubmit={handleSubmit}
                        />][currentStep]
                }
            </div>
        </div>
    )
}


const BasicInformation = ({
    licenseType,
    setLicenseType,
    corporateOfficeLocation,
    setCorporateOfficeLocation,
    licenseNumber,
    setLicenseNumber,
    licenseStartDate,
    setLicenseStartDate,
    category,
    setCategory,
    handleSubmit,
    saveDraftDetails
}) => {
    // requried details
    // - License type ( Sole Propeirtership, Partnership, Company)
    // - Corporate Office (Type any city)
    // - Licence number
    // - License start date
    // - Category (Clothing, Accessories, Shoes, Cosmetics, Perfumes, Healthcare, Electronics, Others - Text input)
    return (
        <div className='knowMore-container'>
            <h2 className="know-more-title">Let's onboard your brand!</h2>
            <h3 className="know-more-subtitle">Basic Information</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label className="know-more-label">License Type</label>
                    <div className="form-flex">
                        <MdBrandingWatermark size={30} className='know-more-social-icon' />
                        <select
                            className="know-more-input"
                            value={licenseType}
                            style={{ width: 220 }}
                            onChange={(e) => setLicenseType(e.target.value)}
                        >
                            <option>Select License Type</option>
                            <option>Sole Proprietorship</option>
                            <option>Partnership</option>
                            <option>Company</option>
                        </select>
                    </div>
                </div>
                <div className="form-control">
                    <label className="know-more-label">Corporate Office Location</label>
                    <div className="form-flex">
                        <TfiLocationPin size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            value={corporateOfficeLocation}
                            className="know-more-input"
                            onChange={(e) => setCorporateOfficeLocation(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-control">
                    <label className="know-more-label">License Number</label>
                    <div className="form-flex">
                        <MdNumbers size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            className="know-more-input"
                            value={licenseNumber}
                            onChange={(e) => setLicenseNumber(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-control">
                    <label className="know-more-label">License Start Date</label>
                    <div className="form-flex">
                        <MdDateRange size={30} className='know-more-social-icon' />
                        <input
                            type="date"
                            value={licenseStartDate}
                            style={{ width: 220 }}
                            className="know-more-input phone-number-input"
                            onChange={(e) => setLicenseStartDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-control">
                    <label className="know-more-label">Business Overview</label>
                    <div className="form-flex">
                        <BiGlobe size={30} className='know-more-social-icon' />
                        <input type="text" value={category} className="know-more-input" onChange={(e) => setCategory(e.target.value)} />
                    </div>
                </div>
                <button type="submit" className="know-more-button" onClick={(e) => saveDraftDetails(e)}>
                    <span style={{ color: 'white' }}>Save & Continue</span>
                </button>
            </form>
        </div>
    )
}

const ContactDetails = ({
    supplierName,
    setSupplierName,
    companyEmail,
    setCompanyEmail,
    companyPhone,
    setCompanyPhone,
    address,
    setAddress,
    countryOfOrigin,
    setCountryOfOrigin,
    country,
    setCountry,
    city,
    setCity,
    licenseCopy,
    setLicenseCopy,
    authorizedContact,
    setAuthorizedContact,
    agreements,
    setAgreements,
    handleSubmit,
    saveDraftDetails
}) => {
    // required details
    // - Supplier Name (as per licence)
    // - Company Email Address
    // - Company Phone number
    // - Address
    // - Country of Origin
    // - Country
    // - City
    // - Company Documents
    // - Upload copy of license

    // --> Authorized Contact Details
    // - Name
    // - Designation
    // - Email ID
    // - Contact number
    // --> Dialog box (checkbox)
    // The authorized signatory as mentioned above is authorized to participate, sign and act on behalf of the supplier.
    return (
        <div className='knowMore-container'>
            <h2 className="know-more-title">Let's onboard your brand!</h2>
            <h3 className="know-more-subtitle">Contact Details</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label className="know-more-label">Supplier Name</label>
                    <div className="form-flex">
                        <BsPersonBadge size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            value={supplierName}
                            placeholder='Supplier Name'
                            className="know-more-input"
                            onChange={(e) => setSupplierName(e.target.value)}
                        />
                    </div>
                </div>
                {/* <div className="form-control">
                    <label className="know-more-label">Company Email Address</label>
                    <div className="form-flex">
                        <MdOutlineEmail size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            className="know-more-input"
                            value={companyEmail}
                            placeholder='Company Email'
                            style={{ width: 400 }}
                            onChange={(e) => setCompanyEmail(e.target.value)}
                        />
                    </div>
                </div> */}
                <div className="form-control">
                    <label className="know-more-label">Company Phone Number</label>
                    <div className="form-flex">
                        <PiPhone size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            className="know-more-input"
                            value={companyPhone}
                            placeholder='Company Phone Number'
                            style={{ width: 400 }}
                            onChange={(e) => setCompanyPhone(e.target.value)}
                        />
                    </div>
                </div>
                {/* <div className="form-control">
                    <label className="know-more-label">Country of Origin</label>
                    <div className="form-flex">
                        <BiFlag size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            placeholder="Country of Origin"
                            className="know-more-input"
                            value={countryOfOrigin}
                            style={{ width: 400 }}
                            onChange={(e) => setCountryOfOrigin(e.target.value)}
                        />
                    </div>
                </div> */}
                <div className="form-control">
                    <label className="know-more-label">Address</label>
                    <div className="form-flex">
                        <FaAddressBook size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            value={address}
                            placeholder='Address'
                            className="know-more-input"
                            style={{ width: 860 }}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-control" style={{ marginBottom: 30 }}>
                    <div className="form-flex">
                        <div className="form-control">
                            <label className="know-more-label">City</label>
                            <div className="form-flex">
                                <FaCity size={30} className='know-more-social-icon' />
                                <input
                                    type="text"
                                    className="know-more-input"
                                    placeholder="City"
                                    style={{ width: 400, height: 60 }}
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-control">
                            <label className="know-more-label">Country</label>
                            <div className="form-flex">
                                <CgFlagAlt size={30} className='know-more-social-icon' />
                                <input
                                    type="text"
                                    placeholder="Country"
                                    className="know-more-input"
                                    style={{ width: 400, height: 60 }}
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-control">
                    <label className="know-more-label">Upload Copy of License</label>
                    {/* uploading single document */}
                    <div className="form-flex">
                        <GrDocument size={30} className='know-more-social-icon' />
                        <div className="document-section">
                            <input
                                type="file"
                                className="know-more-input"
                                style={{ marginBottom: 0 }}
                                onChange={(e) => setLicenseCopy(e.target.files[0])}
                            />
                        </div>
                    </div>
                </div>
                {/*  Authorized Contact Details
                - Name
                - Designation
                - Email ID
                - Contact number
                --> Dialog box (checkbox)
                The authorized signatory as mentioned above is authorized to participate, sign and act on behalf of the supplier.
                */}
                <div className="form-control seperate-section">
                    <label className="know-more-label">Authorized Contact Details</label>
                    <div className="form-flex">
                        <div className="form-flex">
                            <MdBadge size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                placeholder="Name"
                                className="know-more-input"
                                value={authorizedContact.name}
                                style={{ width: 400 }}
                                onChange={(e) => setAuthorizedContact({ ...authorizedContact, name: e.target.value })}
                            />
                        </div>
                        <div className="form-flex">
                            <CgWorkAlt size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                placeholder="Designation"
                                className="know-more-input"
                                style={{ width: 400 }}
                                value={authorizedContact.designation}
                                onChange={(e) => setAuthorizedContact({ ...authorizedContact, designation: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="form-flex">
                        <div className="form-flex">
                            <BiEnvelope size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                className="know-more-input"
                                placeholder="Email ID"
                                style={{ width: 400 }}
                                value={authorizedContact.email}
                                onChange={(e) => setAuthorizedContact({ ...authorizedContact, email: e.target.value })}
                            />
                        </div>
                        <div className="form-flex">
                            <BiPhone size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                className="know-more-input"
                                style={{ width: 400 }}
                                placeholder="Contact Number"
                                value={authorizedContact.contact}
                                onChange={(e) => setAuthorizedContact({ ...authorizedContact, contact: e.target.value })}
                            />
                        </div>
                    </div>
                    {/* declaration */}
                    <label className="know-more-label">
                        <input
                            type="checkbox"
                            checked={agreements.declaration}
                            onChange={(e) => setAgreements({ ...agreements, declaration: e.target.checked })}
                        />
                        The authorized signatory as mentioned above is authorized to participate, sign and act on behalf of the supplier.
                    </label>
                </div>
                <button type="submit" className="know-more-button" onClick={(e) => saveDraftDetails(e)}>

                    <span style={{ color: 'white' }}>Save & Continue</span>
                </button>
            </form>
        </div>
    )
}

const ShopDetails = ({ brandStoreLink, setBrandStoreLink, setInventoryUpload, handleSubmit, saveDraftDetails }) => {
    // required details
    // 3rd Section - Shop Details
    // - Website Link
    // - Dialog box - Upload inventory with download inventory template
    // - Proceed
    return (
        <div className='knowMore-container'>
            <h2 className="know-more-title">Let's onboard your brand!</h2>
            <h3 className="know-more-subtitle">Shop Details</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label className="know-more-label">Website Link</label>
                    <div className="form-flex">
                        <BiGlobe size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            value={brandStoreLink}
                            className="know-more-input"
                            onChange={(e) => setBrandStoreLink(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-control">
                    <label className="know-more-label">Upload Inventory</label>
                    <div className="form-flex">
                        <GrDocument size={30} className='know-more-social-icon' />
                        <div className="document-section">
                            <input
                                type="file"
                                style={{ width: 250, marginTop: 40 }}
                                className="know-more-input"
                                onChange={(e) => setInventoryUpload(e.target.files[0])}
                            />
                        </div>
                        {/* Download Products Template CSV */}
                        <button className="download-template-button">
                            Download CSV Products Template
                        </button>
                    </div>
                </div>

                <button type="submit" className="know-more-button" onClick={(e) => saveDraftDetails(e)}>

                    <span style={{ color: 'white' }}>Save & Continue</span>
                </button>
            </form>
        </div>
    )
}

const BrandAccountDetails = ({
    brandName,
    setBrandName,
    brandTagline,
    setBrandTagline,
    companyEmail,
    setCompanyEmail,
    companyPhone,
    setCompanyPhone,
    brandStoreLink,
    setBrandStoreLink,
    brandSocials,
    setBrandSocials,
    image,
    setImage,
    handleChange,
    handleClick,
    emailStatus,
    handleEmailVerification,
    taxId,
    setTaxId,
    agreements,
    setAgreements,
    handleSubmit,
    userEmail,
    setUserEmail,
    saveDraftDetails
}) => {
    return (
        <div className='knowMore-container'>
            <form onSubmit={handleSubmit}>
                <h2 className="know-more-title">Let's onboard your brand!</h2>
                {/* Image Upload */}
                <div className="form-flex" style={{ height: 200 }}>
                    <div className="brand-logo-container">
                        {/* This is the logo upload container */}
                        <div className="logo-preview" onClick={handleClick} style={{ cursor: 'pointer' }}>
                            {image ? (
                                // Display the uploaded image preview if it exists
                                <img src={image} alt="Brand Logo" />
                            ) : (
                                // Default upload square with an upload icon
                                <div className="default-square" htmlFor="image-upload">
                                    <div className="upload-icon">
                                        <BiUpload size={30} color='#1b1b1b' />
                                    </div>
                                </div>
                            )}
                        </div>
                        <label htmlFor="image-upload" className="upload-label">
                            Brand Logo
                        </label>
                        <input
                            type="file"
                            id="image-upload"
                            accept="image/*"
                            style={{ display: "none" }} // Using label for the file input
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-control">
                        <label className="know-more-label">Brand Name</label>
                        <div className="form-flex">
                            <MdBrandingWatermark size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                value={brandName}
                                className="know-more-input"
                                onChange={(e) => setBrandName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-control">
                        <label className="know-more-label">Brand Tagline</label>
                        <div className="form-flex">
                            <RiPriceTag2Line size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                className="know-more-input"
                                value={brandTagline}
                                onChange={(e) => setBrandTagline(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-control">
                    <label className="know-more-label" style={{ marginTop: 10 }}>Email ID Status <label style={{ fontSize: 14, color: '#ccc' }}>(Autofilled from previous)</label></label>
                    <div className="form-flex" style={{ height: 70 }}>
                        <MdOutlineEmail size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            value={userEmail}
                            className="know-more-input disabled"
                            style={{ width: 400 }}
                            disabled
                        // onChange={(e) => setBrandStoreLink(e.target.value)}
                        />
                        <button type="button" onClick={handleEmailVerification} className='check-verification-btn'>
                            Check Verification
                        </button>
                        <span className={emailStatus === "Verified" ? "verified" : "not-verified"}>
                            {emailStatus}
                        </span>
                    </div>
                </div>

                <div className="form-flex">
                    <div className="form-control">
                        <label className="know-more-label">Phone Number <label style={{ fontSize: 14, color: '#ccc' }}>(Autofilled from previous)</label></label>
                        <div className="form-flex">
                            <PiPhone size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                value={companyPhone}
                                disabled
                                className="know-more-input phone-number-input disabled"
                                style={{ width: 400 }}
                            // onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="form-control">
                        <label className="know-more-label">Brand Store Link <label style={{ fontSize: 14, color: '#ccc' }}>(Autofilled from previous)</label></label>
                        <div className="form-flex">
                            <BiGlobe size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                value={brandStoreLink}
                                className="know-more-input disabled"
                                style={{ width: 400 }}
                                disabled
                            // onChange={(e) => setBrandStoreLink(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-control">
                    <label className="know-more-label">Brand Social Links</label>
                    <div className="form-flex">
                        <div className="form-flex">
                            <BsInstagram size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                placeholder="Instagram URL"
                                className="know-more-input"
                                value={brandSocials.instagram}
                                style={{ width: 400 }}
                                onChange={(e) => setBrandSocials({ ...brandSocials, instagram: e.target.value })}
                            />
                        </div>
                        <div className="form-flex">
                            <BsFacebook size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                placeholder="Facebook URL"
                                className="know-more-input"
                                style={{ width: 400 }}
                                value={brandSocials.facebook}
                                onChange={(e) => setBrandSocials({ ...brandSocials, facebook: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="form-flex">
                        <div className="form-flex">
                            <BsTwitterX size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                className="know-more-input"
                                placeholder="Twitter URL"
                                style={{ width: 400 }}
                                value={brandSocials.twitter}
                                onChange={(e) => setBrandSocials({ ...brandSocials, twitter: e.target.value })}
                            />
                        </div>
                        <div className="form-flex">
                            <BsThreads size={30} className='know-more-social-icon' />
                            <input
                                type="text"
                                className="know-more-input"
                                style={{ width: 400 }}
                                placeholder="Threads URL"
                                value={brandSocials.threads}
                                onChange={(e) => setBrandSocials({ ...brandSocials, threads: e.target.value })}
                            />
                        </div>
                    </div>
                </div>

                {/* <div className="form-control">
                        <label className="know-more-label">Brand Documents</label>
                        {documents.map((doc, index) => (
                            <div className="form-flex">
                                <GrDocument size={30} className='know-more-social-icon' />
                                <div key={index} className="document-section">
                                    <input
                                        type="text"
                                        placeholder="Document Title"
                                        value={doc.title}
                                        className="know-more-input"
                                        style={{ marginBottom: 0 }}
                                        onChange={(e) => handleDocumentChange(index, "title", e.target.value)}
                                    />
                                    <input
                                        type="file"
                                        className="know-more-input"
                                        style={{ marginBottom: 0 }}

                                        onChange={(e) => handleDocumentChange(index, "file", e.target.files[0])}
                                    />
                                </div>
                            </div>
                        ))}
                        <div style={{ display: 'flex', width: "80%", gap: 10 }}>
                            <button type="button" className='know-more-social-button' onClick={handleAddDocument}>
                                <BiPlusCircle size={40} />
                                Add Document
                            </button>
                            <button type="button" className='know-more-social-button' onClick={() => handleRemoveDocument(documents.length - 1)}>
                                <BiMinusCircle size={40} />
                                Remove Last Document
                            </button>
                        </div>
                    </div> */}

                <div className="form-control">
                    <label className="know-more-label">Tax ID</label>
                    <div className="form-flex">
                        <BiMoney size={30} className='know-more-social-icon' />
                        <input
                            type="text"
                            value={taxId}
                            placeholder='Tax ID'
                            className="know-more-input"
                            onChange={(e) => setTaxId(e.target.value)}
                        />
                    </div>
                </div>

                <div className="form-control">
                    <label className="know-more-label">
                        <input
                            type="checkbox"
                            checked={agreements.declaration}
                            onChange={(e) => setAgreements({ ...agreements, declaration: e.target.checked })}
                        />
                        I hereby declare that the information provided is true and correct.
                    </label>
                </div>

                <div className="form-control">
                    <label className="know-more-label">
                        <input
                            type="checkbox"
                            checked={agreements.policy}
                            onChange={(e) => setAgreements({ ...agreements, policy: e.target.checked })}
                        />
                        I have read and agree to the privacy policy, terms of service, and data protection policies.
                    </label>
                </div>
                <button type="submit" className="know-more-button" onClick={(e) => saveDraftDetails(e)}>

                    <span style={{ color: 'white' }}>Save & Continue</span>
                </button>
            </form>
        </div>
    )
}

// Preview and declaration page where all the details are shown and the user can confirm the details of all the different components in this file before submitting 
const PreviewDetails = ({
    brandName,
    brandTagline,
    emailStatus,
    phoneNumber,
    brandStoreLink,
    brandSocials,
    documents,
    taxId,
    agreements,
    currentStep,
    licenseType,
    corporateOfficeLocation,
    licenseNumber,
    licenseStartDate,
    category,
    supplierName,
    companyEmail,
    companyPhone,
    address,
    countryOfOrigin,
    country,
    city,
    licenseCopy,
    authorizedContact,
    registeredEmail,
    inventoryUpload,
    finalDeclaration,
    setFinalDeclaration,
    finalSubmit
}) => {
    return (
        <div className='knowMore-container'>
            <h2 className="know-more-title">Preview Your Details</h2>

            {/* Brand Information Section */}
            <div className="preview-section">
                <h3 className="know-more-subtitle">Brand Details</h3>

                <div className="preview-item">
                    <MdBrandingWatermark size={30} className='know-more-social-icon' />
                    <span><strong>Brand Name:</strong> {brandName}</span>
                </div>

                <div className="preview-item">
                    <RiPriceTag2Line size={30} className='know-more-social-icon' />
                    <span><strong>Brand Tagline:</strong> {brandTagline}</span>
                </div>

                <div className="preview-item">
                    <MdAccountCircle size={30} className='know-more-social-icon' />
                    <span><strong>Registered Account Email:</strong> {registeredEmail} <label className={emailStatus === "Verified" ? "verified" : "not-verified"}>{emailStatus}</label></span>
                </div>

                <div className="preview-item">
                    <PiPhone size={30} className='know-more-social-icon' />
                    <span><strong>Phone Number:</strong> {companyPhone}</span>
                </div>

                <div className="preview-item">
                    <BiGlobe size={30} className='know-more-social-icon' />
                    <span><strong>Brand Store Link:</strong> {brandStoreLink}</span>
                </div>

                <div className="preview-item">
                    <BsInstagram size={30} className='know-more-social-icon' />
                    <span><strong>Instagram:</strong> {brandSocials.instagram || "N/A"}</span>
                </div>

                <div className="preview-item">
                    <BsFacebook size={30} className='know-more-social-icon' />
                    <span><strong>Facebook:</strong> {brandSocials.facebook || "N/A"}</span>
                </div>

                <div className="preview-item">
                    <BsTwitterX size={30} className='know-more-social-icon' />
                    <span><strong>Twitter:</strong> {brandSocials.twitter || "N/A"}</span>
                </div>

                <div className="preview-item">
                    <BsThreads size={30} className='know-more-social-icon' />
                    <span><strong>Threads:</strong> {brandSocials.threads || "N/A"}</span>
                </div>

                <div className="preview-item">
                    <BiMoney size={30} className='know-more-social-icon' />
                    <span><strong>Tax ID:</strong> {taxId}</span>
                </div>
            </div>

            {/* License and Company Information Section */}
            <div className="preview-section">
                <h3 className="know-more-subtitle">License and Company Details</h3>

                <div className="preview-item">
                    <MdBrandingWatermark size={30} className='know-more-social-icon' />
                    <span><strong>License Type:</strong> {licenseType}</span>
                </div>

                <div className="preview-item">
                    <MdNumbers size={30} className='know-more-social-icon' />
                    <span><strong>License Number:</strong> {licenseNumber}</span>
                </div>

                <div className="preview-item">
                    <TfiLocationPin size={30} className='know-more-social-icon' />
                    <span><strong>Corporate Office Location:</strong> {corporateOfficeLocation}</span>
                </div>

                <div className="preview-item">
                    <MdOutlineEmail size={30} className='know-more-social-icon' />
                    <span><strong>Company Email:</strong> {companyEmail}</span>
                </div>

                <div className="preview-item">
                    <PiPhone size={30} className='know-more-social-icon' />
                    <span><strong>Company Phone:</strong> {companyPhone}</span>
                </div>

                <div className="preview-item">
                    <CgFlagAlt size={30} className='know-more-social-icon' />
                    <span><strong>Country of Origin:</strong> {countryOfOrigin}</span>
                </div>

                <div className="preview-item">
                    <TfiLocationPin size={30} className='know-more-social-icon' />
                    <span><strong>Address:</strong> {address}, {city}, {country}</span>
                </div>
            </div>

            {/* Authorized Contact */}
            <div className="preview-section">
                <h3 className="know-more-subtitle">Authorized Contact</h3>

                <div className="preview-item">
                    <CgWorkAlt size={30} className='know-more-social-icon' />
                    <span><strong>Authorized Name:</strong> {authorizedContact.name}</span>
                </div>

                <div className="preview-item">
                    <CgWorkAlt size={30} className='know-more-social-icon' />
                    <span><strong>Designation:</strong> {authorizedContact.designation}</span>
                </div>

                <div className="preview-item">
                    <BiPhone size={30} className='know-more-social-icon' />
                    <span><strong>Contact:</strong> {authorizedContact.contact}</span>
                </div>

                <div className="preview-item">
                    <BiEnvelope size={30} className='know-more-social-icon' />
                    <span><strong>Email:</strong> {authorizedContact.email}</span>
                </div>
            </div>

            {/* License Copy and Inventory Upload */}
            <div className="preview-section">
                <h3 className="know-more-subtitle">Documents & Files</h3>

                <div className="preview-item">
                    <GrDocument size={30} className='know-more-social-icon' />
                    <span><strong>License Copy:</strong> {licenseCopy ? licenseCopy : 'Not Uploaded'}</span>
                </div>

                <div className="preview-item">
                    <GrDocumentStore size={30} className='know-more-social-icon' />
                    <span><strong>Inventory Upload:</strong> {inventoryUpload ? inventoryUpload : 'Not Uploaded'}</span>
                </div>

                {/* Agreement Check */}
                <div className="preview-item">
                    <input type="checkbox" checked={agreements.declaration} readOnly />
                    <label>I hereby declare that the information provided is true and correct.</label>
                </div>

                <div className="preview-item">
                    <input type="checkbox" checked={agreements.policy} readOnly />
                    <label>I have read and agree to the privacy policy and terms of service.</label>
                </div>

                {/* divider */}
                <div className="divider"></div>

                <div className="form-control">
                    <label className="know-more-label">
                        <input type="checkbox" checked={finalDeclaration} onChange={(e) => setFinalDeclaration(e.target.checked)} />
                        I hereby declare that the information provided is true and not conflicting with interests of the supplier.
                    </label>
                </div>

                <button type="submit" className="know-more-button" onClick={finalSubmit}>

                    <Link to="/onboarding-pending" style={{ color: 'white', textDecoration: 'none', width: '100%', display: 'block' }}>
                        Submit for Review
                    </Link>
                </button>
            </div>
        </div>
    );
};
