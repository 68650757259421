import React from 'react';
import { Line, LineChart, ResponsiveContainer } from "recharts";

const websites = ['Google', 'YouTube', 'Instagram', 'Pinterest', 'Facebook', 'Twitter'];
const colors = ['#287f71', '#eb862a', '#abbdd3'];
function generateData(points = 10) {
    return Array.from({ length: points }, (_, i) => ({
        x: i,
        y: Math.random() * 100
    }));
}

export function WebsiteTrafficChart() {
    return (
        <div style={{
            width: '30%',
            backgroundColor: '#fff',
            borderRadius: '12px',
            padding: '20px',
            boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
        }}>
            <h3 style={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#333',
                marginBottom: '10px'
            }}>Traffic by Website</h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {websites.map((site, index) => (
                    <div key={site} style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                    }}>
                        <span style={{
                            width: '80px',
                            fontSize: '14px',
                            color: '#666'
                        }}>{site}</span>
                        <div style={{ width: '100px', height: '24px' }}>
                            <ResponsiveContainer width="100%" height="95%">
                                <LineChart data={generateData()}>
                                    <Line
                                        type="monotone"
                                        dataKey="y"
                                        stroke={colors[index % colors.length]}
                                        strokeWidth={1}
                                        dot={false}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

