import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { apiCall } from '../backend/api/apiService';
import { useParams } from 'react-router-dom';


function AddProduct() {
    const { productID } = useParams();
    const user = useAuthStore(state => state.user);
    const brand_id = user.brand.id;
    const [allowAffiliations, setAllowAffiliations] = useState(true);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productTags, setProductTags] = useState('');
    const [productSKU, setProductSKU] = useState('');
    const [regularPrice, setRegularPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [affiliatePrice, setAffiliatePrice] = useState('');
    const [affiliateCommission, setAffiliateCommission] = useState('');
    const [affiliateRequirement, setAffiliateRequirement] = useState('');
    const [affiliationLink, setAffiliationLink] = useState('');
    const [category, setCategory] = useState('');
    const [newCategory, setNewCategory] = useState('');

    const toggleAffiliations = () => {
        setAllowAffiliations(!allowAffiliations);
    };

    const [mainImage, setMainImage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [mainImageData, setMainImageData] = useState(null);
    const [galleryImagesData, setGalleryImagesData] = useState([]);

    const [categories, setCategories] = useState([]); // Holds the categories at each level
    const [selectedCategories, setSelectedCategories] = useState([]); // Holds selected category IDs at each level
    const [categoriesArray, setCategoriesArray] = useState([]); // Holds the selected category IDs in an array

    const navigate = useNavigate();

    const fetchProductDetails = async () => {
        try {
            const response = await apiCall(`/api/products/product/${productID}`, "GET");
            console.log(response);

            if (response && response.data) {
                const product = response.data;
                setProductName(product.name);
                setProductDescription(product.description);
                setProductTags(product.product_tags);
                setProductSKU(product.product_sku);
                setRegularPrice(product.regular_price);
                setSalePrice(product.sale_price);
                setAffiliatePrice(product.affiliate_price);
                setMainImage(product.main_image);
                setGalleryImages(product.image_gallery);
                setCategory(product.category_id);
            }

            if (brand_id !== response.data.brand_id) {
                navigate('/shop');
            }

            // Get Product Affiliations
            const affiliationsResponse = await apiCall(`/api/products/affiliations/${productID}`, "GET");
            if (affiliationsResponse && affiliationsResponse.data) {
                const affiliation = affiliationsResponse.data;
                console.log("AFF:", affiliation);
                setAllowAffiliations(true);
                setAffiliateCommission(affiliation.affiliation_commission_percentage);
                setAffiliateRequirement(affiliation.affiliation_minimum_requirement);
                setAffiliationLink(affiliation.affiliation_link);
            }

            // Get the category array
            const categoryResponse = await apiCall(`/api/products/categories/array/${response.data.category_id}`, "GET");
            if (categoryResponse && categoryResponse.categoriesArray) {
                const category = categoryResponse.categoriesArray;
                console.log("CAT:", category);
                setCategoriesArray(category);
            }

        } catch (error) {
            console.error("Error fetching product details:", error);
        }
    };

    useEffect(() => {
        // Wrapping the async function inside an inner function
        const fetchData = async () => {
            if (productID) {
                await fetchProductDetails();
            }
        };

        fetchData();
    }, [productID]); // Only re-run when productID changes


    return (
        <div className='add-product'>
            <div className="add-product-header">
                <div>
                    <h1>View Product</h1>
                    <p style={{ marginTop: -20 }}>View product in detail.</p>
                </div>
                <div style={{ display: 'flex' }}>
                    {/* <button className="bulk-update-button">Bulk Upload CSV</button> */}
                    <button className="save-product-button"
                        onClick={() => navigate(`/shop/edit-product/${productID}`)}
                    >
                        Edit Product
                    </button>
                </div>
            </div>
            <div className="product-form">
                <div className="form-section basic-info">
                    <h3>Basic Information</h3>
                    <label htmlFor="product-name">Product Name</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Product Name"
                            value={productName}
                            disabled
                        />
                    </div>
                    <label htmlFor="product-description">Product Description</label>
                    <div className='product-textarea'>
                        <textarea
                            placeholder="Product Description"
                            value={productDescription}
                            disabled
                        ></textarea>
                    </div>
                    <label htmlFor="product-tags">Product Tags</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Product Tags"
                            value={productTags}
                            disabled
                        />
                    </div>
                    <label htmlFor="product-sku">Product SKU</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Product SKU"
                            value={productSKU}
                            disabled
                        />
                    </div>
                </div>

                <div className="form-section affiliations">
                    <h3>Affiliations</h3>
                    <div style={{ display: 'flex', gap: 10, margin: "10px 0px" }}>
                        <span>Allow Affiliations</span>
                        <label className="switch">
                            <input type="checkbox" checked={allowAffiliations} disabled />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {allowAffiliations && <>
                        <label htmlFor="affiliate-commission">Affiliate Commission</label>
                        <div className='product-input'>
                            <input
                                type="text"
                                placeholder="Affiliate Commission %"
                                value={affiliateCommission}
                                disabled={!allowAffiliations}
                            />
                        </div>
                        <label htmlFor="affiliate-requirement">Affiliate Requirement</label>
                        <div className='product-input'>
                            <input
                                type="text"
                                placeholder="Creator Minimum Follower Requirement"
                                value={affiliateRequirement}
                                onChange={(e) => setAffiliateRequirement(e.target.value)}
                                disabled={!allowAffiliations}
                            />
                        </div>
                        <label htmlFor="affiliation-link">Affiliation Link</label>
                        <div className='product-input'>
                            <input
                                type="text"
                                placeholder="Affiliation Link"
                                value={affiliationLink}
                                onChange={(e) => setAffiliationLink(e.target.value)}
                                disabled={!allowAffiliations}
                            />
                        </div>
                    </>
                    }
                </div>
                <div className="form-section product-images">
                    <h3>Product Images</h3>
                    <div className="image-upload">
                        <div className="main-image">
                            <span style={{ width: '100%' }}>Main Image</span>
                            <div className="image-placeholder-main">
                                {mainImage ? (
                                    <img src={mainImage} alt="Main" className="uploaded-image" />
                                ) : (
                                    <>
                                        <label htmlFor="main-image-upload" className="image-upload-label" style={{ width: '100%', height: '100%' }}>
                                            <BiPlus size={40} />
                                        </label>
                                        <input
                                            type="file"
                                            id="main-image-upload"
                                            style={{ display: 'none' }}
                                            disabled
                                            accept="image/*"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="gallery-images">
                            <span style={{ width: '100%' }}>Product Gallery Images</span>
                            {[...Array(4)].map((_, i) => (
                                <div key={i} className="image-placeholder">
                                    {galleryImages[i] ? (
                                        <img src={galleryImages[i]} alt={`Gallery ${i}`} className="uploaded-image" />
                                    ) : (
                                        <>
                                            <label htmlFor={`gallery-image-upload-${i}`} className="image-upload-label" style={{ width: '100%', height: '100%' }}>
                                                <BiPlus size={20} />
                                            </label>
                                            <input
                                                type="file"
                                                id={`gallery-image-upload-${i}`}
                                                style={{ display: 'none' }}
                                                disabled
                                                accept="image/*"
                                            />
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <br />
                    <label htmlFor="image-urls">Image URLs</label>
                    <div className='product-textarea image-urls-area'>
                        <textarea placeholder="Image URLs (separated by comma)" disabled></textarea>
                    </div>
                </div>
                <div className="form-section price-section">
                    <h3>Price</h3>
                    <label htmlFor="regular-price">Regular Price</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Regular Price"
                            value={regularPrice}
                            disabled
                        />
                    </div>
                    <label htmlFor="sale-price">Discount Price</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Discount Price"
                            value={salePrice}
                            disabled
                        />
                    </div>
                    <label htmlFor="affiliate-price">Affiliate Price</label>
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Affiliate Price"
                            value={affiliatePrice}
                            disabled
                        />
                    </div>
                    <label htmlFor="discount-expiry-date">Discount Expiry Date</label>
                    <div className='product-input'>
                        <input type="date" placeholder="Discount Expiry Date" disabled />
                    </div>
                </div>
            </div>
            {/* categoriesArray: ['Appliances', 'Laundry Appliances', 'Washers & Dryers', 'Clothes Washing Machines'] */}
            <div className="form-section">
                <h3>Category</h3>
                {categoriesArray.map((category, index) => (
                    <div key={index} className='category-section'>
                        <label
                            htmlFor={`category-level-${index}`}
                            style={{
                                display: 'block',
                                marginTop: 20,
                                fontWeight: 'bold'
                            }}
                        >
                            Category Level {index + 1}
                        </label>
                        <br />
                        <select
                            id={`category-level-${index}`}
                            className='add-category-select'
                            value={category}
                            disabled
                            style={{ width: '20%', marginLeft: 20 }}
                        >
                            <option value={category}>{category}</option>
                        </select>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AddProduct