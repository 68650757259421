import React, { useState } from 'react';
import { BsApple, BsGoogle } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore'; // Zustand store for managing authentication state
import { authInstance } from '../backend/firebase/firebase'; // Firebase auth instance
import { signInWithEmailAndPassword } from 'firebase/auth';
import { apiCall } from '../backend/api/apiService';
import useAuthTokenStore from '../store/AuthTokenContext';

export default function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const setUser = useAuthStore((state) => state.setUser); // Zustand action to set user
    const navigate = useNavigate(); // To handle redirection
    const loginUser = useAuthTokenStore((state) => state.loginUser);
    const registerUser = useAuthTokenStore((state) => state.registerUser);

    const logout = useAuthStore((state) => state.logout);
    // call logout
    // logout();
    console.log('User logged out:', useAuthStore.getState());

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Firebase sign in with email and password
            const userCredential = await signInWithEmailAndPassword(authInstance, email, password);
            const user = userCredential.user;

            // Step 2: Attempt Login via Backend
            let response2;
            try {
                response2 = await loginUser({ username: email, password: user.uid });
                console.log("Login Response", response2);
            } catch (loginError) {
                console.log("Login failed, attempting to register:", loginError);

                // Step 3: Fallback to Registration
                try {
                    await registerUser({
                        username: email,
                        password: user.uid,
                        user_id: user.uid,
                    });
                    console.log("Registration successful, logging in...");

                    // Immediately log in the newly registered user to get the token
                    response2 = await loginUser({ username: email, password: user.uid });
                    console.log("Post-registration Login Response", response2);
                } catch (registerError) {
                    console.error("Registration failed:", registerError);
                    throw new Error('User registration failed.');
                }
            }

            // Get brand by firebase uid
            const response = await apiCall(`/api/brands/${user.uid}`, 'GET');
            // console.log('Brand data:', response);
            // var onboardingCompleteStatus = true;

            // if (response.user.is_verified === false) {
            //     // check onboarding status
            //     const response2 = await apiCall(`/api/brands/business-details/${user.uid}`, 'GET');
            //     const brandDetails = response2.brand[0].brand_business_details[0]
            //     console.log(brandDetails);
            //     // Check if onboarding is complete by seeing if any field is null
            //     onboardingCompleteStatus = brandDetails.submitted
            // }

            // Save user in Zustand (set user as logged in)
            setUser({
                uid: user.uid,
                email: user.email,
                brand: response.brand[0],
                // onboardingVerified: response.user.verified,
                // onboardingComplete: onboardingCompleteStatus
            });

            console.log('User logged in:', useAuthStore.getState());

            // Check if onboarding is complete from auth store
            const onboardingComplete = useAuthStore.getState().onboardingComplete;

            // Redirect to onboarding if onboarding is pending
            // if (!onboardingComplete) {
            //     navigate('/onboarding');
            //     return;
            // } else {
            //     // Redirect to dashboard after successful login
            //     navigate('/dashboard');
            // }
        } catch (error) {
            console.error('Login failed:', error);
            alert('Login Failed: ' + error.message);
        }
    };

    return (
        <div className='signin-container'>
            <div className='signin-left'>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url('https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?q=80&w=2094&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(30px)', // Added blur effect
                    borderRadius: '50px' // Match the container's border radius
                }}></div>
                <div style={{ position: 'relative', zIndex: 1 }}>
                    <h1 className='lynkd-logo'>LYNKD</h1>
                </div>
            </div>
            <div className='signin-right'>
                <div className="sign-in-container">
                    <h2 className="sign-in-title">Sign In</h2>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="email" className="sign-in-label">Email</label>
                        <input
                            type="email"
                            placeholder="Email"
                            className="sign-in-input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label htmlFor="password" className="sign-in-label">Password</label>
                        <input
                            type="password"
                            placeholder="Password"
                            className="sign-in-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit" className="sign-in-button">
                            Login
                        </button>
                    </form>
                    <div className="sign-in-divider">
                        <div className="sign-in-line"></div>
                        <span className="sign-in-or-text">or</span>
                        <div className="sign-in-line"></div>
                    </div>
                    {/* <div className="sign-in-social-buttons">
                        <button className="sign-in-social-button">
                            <span className="sign-in-social-icon"><BsGoogle size={30} /></span> Google
                        </button>
                        <button className="sign-in-social-button">
                            <span className="sign-in-social-icon"><BsApple size={30} /></span> Apple
                        </button>
                    </div> */}
                    <div className="sign-in-signup-text">
                        Don't have an account?
                        <button className="sign-in-signup-button">
                            <Link to="/sign-up" style={{ color: 'white', textDecoration: 'none', width: '100%', display: 'block' }}>
                                Sign Up
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>);
}