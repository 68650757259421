import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { apiCall } from '../backend/api/apiService';

const useAuthTokenStore = create(
    persist(
        (set) => ({
            authToken: null,
            setAuthToken: (value) => set({ authToken: value }),

            registerUser: async (user) => {
                try {
                    const response = await apiCall('/api/tokenAuthentication/register', 'POST', user);
                    console.log('User registered:', response);
                    return response;
                } catch (error) {
                    console.error('Error registering user:', error);
                    throw new Error('Registration failed');
                }
            },

            loginUser: async (user) => {
                try {
                    const response = await apiCall('/api/tokenAuthentication/login', 'POST', user);
                    if (response && response.token) {
                        set({ authToken: response.token });
                    }
                    console.log('User logged in:', response);
                    return response;
                } catch (error) {
                    console.error('Error logging in user:', error);
                    throw new Error('Login failed');
                }
            },

            logoutUser: async () => {
                try {
                    set({ authToken: null });
                    console.log('User logged out and token cleared.');
                } catch (error) {
                    console.error('Error logging out user:', error);
                    throw new Error('Logout failed');
                }
            },
        }),
        {
            name: 'authToken-storage',
            storage: createJSONStorage(() => localStorage), // or sessionStorage
        }
    )
);

export default useAuthTokenStore;
