import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { apiCall } from '../backend/api/apiService';

export const useAuthStore = create(
    persist(
        (set) => ({
            user: null,
            isAuthenticated: false,
            onboardingComplete: false,
            onboardingVerified: false,

            // Action to set the user state after sign-up or login
            setUser: async (userData) => {
                const onboardingStatus = await checkOnboardingStatus(userData.uid); // Wait for onboarding status
                set({
                    user: userData,
                    isAuthenticated: !!userData, // Set to true if userData exists
                    onboardingComplete: onboardingStatus[0], // Set onboardingComplete based on the result,
                    onboardingVerified: onboardingStatus[1],
                });
            },

            // Action to set onboarding status
            setOnboardingStatus: (status) => set({
                onboardingComplete: status,
            }),

            // Action to log out
            logout: () => set({
                user: null,
                isAuthenticated: false,
                onboardingComplete: false,
            }),

            // function to get all fields
            getState: () => {
                return {
                    user: useAuthStore.getState().user,
                    isAuthenticated: useAuthStore.getState().isAuthenticated,
                    onboardingComplete: useAuthStore.getState().onboardingComplete,
                    onboardingVerified: useAuthStore.getState().onboardingVerified,
                };
            },
        }),
        {
            name: 'auth-storage', // name for localStorage key
            getStorage: () => localStorage, // persist in localStorage
        }
    )
);


// Function to check onboarding status of a user
const checkOnboardingStatus = async (uid) => {
    // Call backend API to check onboarding status
    const response = await apiCall(`/api/brands/business-details/${uid}`, 'GET');
    console.log('Onboarding status response:', response);
    // Check if onboarding is complete by seeing if any field is null
    if (!response.brand[0]?.brand_business_details[0]) {
        return [false, false];
    }
    const onboardingComplete = Object.values(response.brand[0]?.brand_business_details[0]).every((value) => value != null);
    console.log('Onboarding status:', onboardingComplete);
    return [onboardingComplete, response.brand[0].is_verified];
};