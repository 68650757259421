import React, { useEffect, useState } from 'react'
import { BiChevronLeft, BiChevronRight, BiFilter, BiSearch } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { useAuthStore } from '../store/authStore';
import { apiCall } from '../backend/api/apiService';

const orders = [
    {
        "name": "Liam Johnson",
        "profilePhoto": "https://randomuser.me/api/portraits/men/25.jpg",
        "category": "Gaming",
        "followers": 1200000,
        "engagementRate": "8.2%",
        "brandsAffiliated": 30,
        "averageLikes": 98400,
        "averageComments": 3200,
        "platforms": ["Twitch", "YouTube", "Twitter"],
        "contentFormat": ["Live Streams", "Game Walkthroughs", "Product Unboxings"],
        "topAffiliatedBrands": ["Razer", "Corsair", "Xbox"]
    },
    {
        "name": "Emily Clark",
        "profilePhoto": "https://randomuser.me/api/portraits/women/47.jpg",
        "category": "Parenting",
        "followers": 350000,
        "engagementRate": "5.0%",
        "brandsAffiliated": 10,
        "averageLikes": 17500,
        "averageComments": 400,
        "platforms": ["Instagram", "YouTube"],
        "contentFormat": ["Parenting Tips", "Family Vlogs", "Product Recommendations"],
        "topAffiliatedBrands": ["Pampers", "Gerber", "Huggies"]
    },
    {
        "name": "Noah Harris",
        "profilePhoto": "https://randomuser.me/api/portraits/men/16.jpg",
        "category": "Photography",
        "followers": 850000,
        "engagementRate": "7.0%",
        "brandsAffiliated": 22,
        "averageLikes": 59500,
        "averageComments": 1800,
        "platforms": ["Instagram", "YouTube"],
        "contentFormat": ["Photography Tutorials", "Camera Reviews", "Behind-the-Scenes Shoots"],
        "topAffiliatedBrands": ["Canon", "Nikon", "Sony"]
    },
    {
        "name": "Ava Roberts",
        "profilePhoto": "https://randomuser.me/api/portraits/women/37.jpg",
        "category": "Food & Cooking",
        "followers": 490000,
        "engagementRate": "6.3%",
        "brandsAffiliated": 15,
        "averageLikes": 30870,
        "averageComments": 1400,
        "platforms": ["Instagram", "YouTube", "TikTok"],
        "contentFormat": ["Recipe Videos", "Cookbook Reviews", "Food Photography"],
        "topAffiliatedBrands": ["Tasty", "KitchenAid", "Blue Apron"]
    },
];

const apiResponse =
    [
        {
            "id": 4,
            "creator_id": 2,
            "brand_id": 8,
            "status": "pending",
            "created_at": "2024-10-16T20:08:33.136277",
            "creator": {
                "id": 2,
                "user_id": 29,
                "user": {
                    "id": 29,
                    "email": "abhinavkk1562003@gmail.com",
                    "username": "abhinav",
                    "bio": "This is a bio from Lynkd.",
                    "first_name": "Abhinav",
                    "last_name": "Kottayil",
                    "profile_picture": "https://lynkd-all-media-storage.s3.amazonaws.com/profile-pictures/3AHrd6oBAzaiR9JyYEk4s7GHylg1/pic_1729614199422.jpeg"
                }
            }
        }
    ]


export default function BrandAmbassadorsRequests() {
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage, setOrdersPerPage] = useState(10); // Default is 3 orders per page
    const [selectedStatus, setSelectedStatus] = useState('');  // Filter state
    const [fetchedOrders, setFetchedOrders] = useState([]);
    // Calculate the indexes of orders for the current page
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const [filteredOrders, setFilteredOrders] = useState(orders.slice(indexOfFirstOrder, indexOfLastOrder));
    const [activeStatuses, setActiveStatuses] = useState(
        filteredOrders.reduce((acc, campaign) => {
            acc[campaign.campaignId] = campaign.status === 'Active';
            return acc;
        }, {})
    );

    const toggleStatus = (campaignId) => {
        setActiveStatuses((prevStatuses) => ({
            ...prevStatuses,
            [campaignId]: !prevStatuses[campaignId]
        }));
    };
    const nextPage = () => {
        if (currentPage < Math.ceil(orders.length / ordersPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const [search, setSearch] = useState('');

    // Filter and search functionality
    useEffect(() => {
        const searchedOrders = fetchedOrders.filter(creator =>
            creator.name.toLowerCase().includes(search.toLowerCase()) ||
            creator.category.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredOrders(searchedOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    }, [search, currentPage, ordersPerPage]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [selectedCreator, setSelectedCreator] = useState(null);

    const user = useAuthStore(state => state.user);
    const brand_id = user.brand.id;

    const openModal = (creatorName, requestID) => {
        console.log(`Opening modal for ${creatorName} with request ID: ${requestID}`);
        setSelectedCreator({ creatorName: creatorName, requestID: requestID });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setResponseMessage("");
    };

    const submitResponse = () => {
        console.log(`Responding to ${selectedCreator.creatorName}: ${responseMessage}`);

        // Additionally send message to creator
        // todo: send message to creator

        // Accept request
        acceptRequest(selectedCreator.requestID);
        closeModal();
    };


    // Fetch and transform API response
    useEffect(() => {
        const fetchRequests = async () => {
            // const response = await apiCall("/api/affiliations/8/pending", "GET");
            // const response = { data: [] };
            const response = await apiCall(`/api/affiliations/${brand_id}/pending`, "GET");
            console.log(response);
            const transformedOrders = response.data.map(request => ({
                requestID: request.id,
                name: request.creator.first_name + ' ' + request.creator.last_name,
                profilePhoto: request.creator.profile_picture,
                status: request.status,
                // Below fields will be updated once the data is ready
                category: "General", // Use mock or default value as category isn't in the API response
                followers: 0, // Set default values or pull real values if available
                engagementRate: "N/A", // Default values if missing in response
                averageLikes: 0,
                averageComments: 0,
                brandsAffiliated: 0 // or any other default
            }));
            setFetchedOrders(transformedOrders);
            setFilteredOrders(transformedOrders.slice(indexOfFirstOrder, indexOfLastOrder));
        };
        fetchRequests();
    }, []);

    // Handle reject request
    const rejectRequest = async (requestID) => {
        const response = await apiCall(`/api/affiliations/${requestID}`, "PUT", { status: "rejected" });
        console.log(response);
        // reload page
        window.location.reload();
    };

    // Handle accept request
    const acceptRequest = async (requestID) => {
        const response = await apiCall(`/api/affiliations/${requestID}`, "PUT", { status: "approved" });
        console.log(response);
        // reload page
        window.location.reload();
    };


    return (
        <div className="shop">
            <div className='shop-header'>
                <div className="shop-container">
                    <h1 style={{ width: 520 }}>Brand Ambassadors Requests</h1>
                    <div className="shop-search">
                        <BiSearch color='black' size={30} />
                        <input type="text" placeholder="Search Creators by Name or Category"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </div>
                </div>
                <div className="shop-container">
                    <div className="products-per-page">
                        <label htmlFor="ordersPerPage" style={{ width: 150 }}>Creators per page: </label>
                        <select
                            id="ordersPerPage"
                            onChange={(e) => setOrdersPerPage(Number(e.target.value))}
                            value={ordersPerPage}
                        >
                            <option value={3}>3</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                        </select>
                    </div>
                    {/* <button className="filter-button">
                        <BiFilter color='blue' size={30} />
                        Filter
                    </button> */}
                    <Link to={"/brand-ambassadors"}
                        style={{ color: 'white', textDecoration: 'none', width: 190 }}
                        className="add-product-button"
                    >
                        View Affiliated Creators
                    </Link>
                </div>
            </div>
            <div className="table-container">
                <table className="product-table" style={{ width: "99%", textAlign: 'center' }}>
                    <thead>
                        <tr>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Followers</th>
                            <th>Engagement Rate</th>
                            <th>Average Likes</th>
                            <th>Average Comments</th>
                            <th>Brands Affiliated</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredOrders.length > 0 ? (

                                filteredOrders.map((creator, index) => (
                                    <tr key={creator.name} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8' }}>
                                        {/* Profile Photo */}
                                        <td>
                                            <div
                                                style={{
                                                    width: 40
                                                }}>
                                                <img
                                                    src={creator.profilePhoto}
                                                    alt={creator.name}
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            </div>
                                        </td>

                                        {/* Name */}
                                        <td>
                                            <div
                                                style={{
                                                    width: 100
                                                }}>{creator.name}</div>
                                        </td>

                                        {/* Category */}
                                        <td>
                                            <div
                                                style={{
                                                    width: 50
                                                }}>{creator.category}</div>
                                        </td>

                                        {/* Followers */}
                                        <td>
                                            <div
                                                style={{
                                                    width: 50
                                                }}>{creator.followers.toLocaleString()}</div>
                                        </td>

                                        {/* Engagement Rate */}
                                        <td>
                                            <div
                                                style={{
                                                    width: 80
                                                }}>{creator.engagementRate}</div>
                                        </td>

                                        {/* Average Likes */}
                                        <td>
                                            <div
                                                style={{
                                                    width: 100
                                                }}>{creator.averageLikes.toLocaleString()}</div>
                                        </td>

                                        {/* Average Comments */}
                                        <td>
                                            <div
                                                style={{
                                                    width: 100
                                                }}>{creator.averageComments.toLocaleString()}</div>
                                        </td>
                                        {/* Brands Affiliated */}
                                        <td>
                                            <div
                                                style={{
                                                    width: 120
                                                }}>{creator.brandsAffiliated}</div>
                                        </td>
                                        {/* Actions */}
                                        <td>
                                            <div
                                                style={{
                                                    width: 120
                                                }}>
                                                <button
                                                    className='product-table-edit-btn'
                                                    onClick={() => openModal(creator.name, creator.requestID)}
                                                >
                                                    Respond
                                                </button>
                                            </div>

                                            <br />
                                            <div
                                                style={{
                                                    width: 120
                                                }}>
                                                <button className='product-table-view-btn' onClick={() => rejectRequest(creator.requestID)}>Reject</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) :
                                (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>
                                            No affiliation requests found. Interact with creators to see requests here.
                                        </td>
                                    </tr>
                                )}
                    </tbody>
                </table>

            </div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Respond to {selectedCreator.creatorName}</h2>
                        <div className='product-textarea'>
                            <textarea
                                value={responseMessage}
                                onChange={(e) => setResponseMessage(e.target.value)}
                                placeholder="Write your message..."
                                rows="5"
                                style={{ width: '100%', padding: '10px' }}
                            />
                        </div>
                        <div className="modal-actions">
                            <button onClick={submitResponse} className="product-table-edit-btn">Submit</button>
                            <button onClick={closeModal} className="product-table-view-btn">Cancel</button>
                        </div>
                    </div>
                </div>
            )}


            <div className="pagination">
                <span>
                    Showing {indexOfFirstOrder + 1} - {Math.min(indexOfLastOrder, ordersPerPage)} of {fetchedOrders.length} Creators
                </span>
                <div className="pagination-controls">
                    <button className="prev-btn" onClick={prevPage} disabled={currentPage === 1}>
                        <BiChevronLeft size={40} color='black' />
                    </button>
                    <span>{currentPage} / {Math.ceil(orders.length / ordersPerPage)}</span>
                    <button className="next-btn" onClick={nextPage} disabled={currentPage === Math.ceil(orders.length / ordersPerPage)}>
                        <BiChevronRight size={40} color='black' />
                    </button>
                </div>
            </div>
        </div>
    )
}