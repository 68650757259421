import axios from 'axios';
import useAuthTokenStore from '../../store/AuthTokenContext';

export const apiCall = async (endpoint, method, data) => {
  try {
    console.log(`Making API request to: ${process.env.REACT_APP_API_URL}${endpoint}`);

    // Get the auth token from Zustand store
    const authToken = useAuthTokenStore.getState().authToken;

    // Prepare headers
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    // Add Authorization header if token exists
    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`;
    }

    const response = await axios({
      method: method,
      url: `${process.env.REACT_APP_API_URL}${endpoint}`,
      data: data,
      headers: headers,
    });

    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('API request failed:', error);
    throw new Error('API request failed');
  }
};
