import React from "react";

const StatsCard = ({ icon, title, value }) => {
    return (
        <div className="stats-card">
            {/* {icon && <span className="icon">{icon}</span>} */}
            {/* {icon} */}
            <h3 className="stats-title">{title}</h3>
            <p>{value}</p>
        </div>
    );
};

export default StatsCard;