import React, { useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiFilter, BiSearch } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { apiCall } from '../backend/api/apiService';
import { useAuthStore } from '../store/authStore';

// const productsTemp = [
//     { id: 1, name: 'Hawaiian Shoes', category: 'Footwear', price: 99.99, inventory: 100, image: HawaiianShoes },
//     { id: 2, name: 'Vitamin C Serum', category: 'Skin Care', price: 9.99, inventory: 50, image: VitaminCSerum },
//     { id: 3, name: 'Wireless Headphones', category: 'Electronics', price: 199.99, inventory: 10, image: WirelessHeadphones },
//     { id: 4, name: 'Smart Watch', category: 'Electronics', price: 159.99, inventory: 100, image: SmartWatch },
//     { id: 5, name: 'Chanel Perfume', category: 'Perfume', price: 299.99, inventory: 70, image: ChanelN5 },
//     { id: 6, name: 'Fresh Orange Juice', category: 'Beverages', price: 5.99, inventory: 50, image: PressedOrangeJuice },
// ]

export default function Shop() {
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(10);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [search, setSearch] = useState('');
    const user = useAuthStore(state => state.user);
    const brand_id = user.brand.id;

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

    const nextPage = () => {
        if (currentPage < Math.ceil(products.length / productsPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const getProducts = async () => {
        const response = await apiCall(`/api/products/brand/${brand_id}`, 'GET');
        if (response && response.data && Object.values(response.data).length > 0) {
            setProducts(Object.values(response.data));
        } else {
            setProducts([]);
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        if (products.length != 0) {
            const filteredProductsTemp = products.filter(product =>
                product.name.toLowerCase().includes(search.toLowerCase())
            );
            const slicedFilters = filteredProductsTemp.slice(indexOfFirstProduct, indexOfLastProduct);
            setFilteredProducts(slicedFilters);
        }
    }, [search, products, productsPerPage, currentPage, indexOfFirstProduct, indexOfLastProduct]);

    const navigate = useNavigate();
    return (
        <div className="shop">
            <div className='shop-header'>
                <div className="shop-container">
                    <h1>Shop</h1>
                    <div className="shop-search">
                        <BiSearch color='black' size={30} />
                        <input
                            type="text"
                            placeholder="Search For Products"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </div>
                </div>
                <div className="shop-container">
                    <div className="products-per-page">
                        <label htmlFor="productsPerPage">Products per page: </label>
                        <select
                            id="productsPerPage"
                            onChange={(e) => setProductsPerPage(Number(e.target.value))}
                            value={productsPerPage}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                    </div>
                    <button className="filter-button">
                        <BiFilter color='#000' size={30} />
                        Filter
                    </button>
                    <Link to="/shop/add-product"
                        style={{ color: 'white', textDecoration: 'none' }}
                        className="add-product-button"
                    >
                        Add Product
                    </Link>
                </div>
            </div>
            <div className="table-container">
                <table className="product-table">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Product</th>
                            <th>Category</th>
                            <th>Regular Price</th>
                            <th>Sale Price</th>
                            <th>Affiliate Price</th>
                            <th>Inventory</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts.length > 0 ? (
                            filteredProducts.map((product, index) => (
                                <tr key={product.id} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8' }}>
                                    <td>
                                        <div style={{ background: 'white', width: 100, height: 100, overflow: 'hidden', borderRadius: 10 }}>
                                            <img src={product.main_image} alt={product.name} className='product-table-image' />
                                        </div>
                                    </td>
                                    <td>{product.name}</td>
                                    <td style={{ width: 250 }}>{product.category_path}</td>
                                    <td>${product.regular_price}</td>
                                    <td>${product.sale_price}</td>
                                    <td>${product.affiliate_price}</td>
                                    <td>{product.stock} Stock</td>
                                    <td
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            marginTop: 30
                                        }}
                                    >
                                        <button className="product-table-view-btn" onClick={() => {
                                            navigate(`/shop/product/${product.id}`)
                                        }}>View</button>
                                        <button className="product-table-edit-btn"
                                            onClick={() => {
                                                navigate(`/shop/edit-product/${product.id}`)
                                            }}
                                        >Edit</button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>
                                    No products found. <Link to="/shop/add-product">Add a product</Link> to see it here.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="pagination">
                    <span>
                        Showing {indexOfFirstProduct + 1} - {Math.min(indexOfLastProduct, products.length)} of {products.length} Products
                    </span>
                    <div className="pagination-controls">
                        <button className="prev-btn" onClick={prevPage} disabled={currentPage === 1}>
                            <BiChevronLeft size={40} color='black' />
                        </button>
                        <span>{currentPage} / {Math.ceil(products.length / productsPerPage)}</span>
                        <button className="next-btn" onClick={nextPage} disabled={currentPage === Math.ceil(products.length / productsPerPage)}>
                            <BiChevronRight size={40} color='black' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
