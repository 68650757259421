import React from "react";
import { FaShippingFast, FaBox, FaEnvelope, FaChartBar, FaShoppingBag, FaCreditCard } from "react-icons/fa";
import GlitchText from "./GlitchText";
import { FaPeopleGroup, FaPowerOff } from "react-icons/fa6";
import { BiBox, BiChart, BiCreditCard, BiEnvelope, BiGroup, BiShoppingBag, BiSolidShoppingBag, BiSolidTruck, BiSupport } from "react-icons/bi";
import { signOut } from "firebase/auth";
import { authInstance } from "../backend/firebase/firebase";
import { useAuthStore } from "../store/authStore";

const Sidebar = () => {
    const activePage = window.location.pathname;
    const shopPages = ['/shop', '/shop/add-product'];
    const isActiveShopPage = shopPages.includes(activePage) ||
        activePage.startsWith('/shop/edit-product/') ||
        activePage.startsWith('/shop/product/');
    const adsPages = ['/ads-management', '/ads-management/create-campaign'];
    const brandAmbassadorsPages = ['/brand-ambassadors', '/brand-ambassadors/requests'];
    const logout = useAuthStore((state) => state.logout);

    const logoutUser = async () => {
        // Call firebase logout
        await signOut(authInstance);
        // Clear local storage
        logout();
    };

    return (
        <div className="sidebar">
            {/* <GlitchText text="lynkd" /> */}
            <h1 className='lynkd-logo-sidebar'>LYNKD</h1>
            <div className="sidebar-menu">
                <a href="/dashboard" className={activePage === "/dashboard" ? 'active' : undefined}>
                    <BiSolidTruck size={26} />
                    <span>Dashboard</span>
                </a>
                <a href="/shop" className={isActiveShopPage ? 'active' : undefined}>
                    <BiShoppingBag size={26} />
                    <span>Shop</span>
                </a>
                <a href="/orders" className={activePage === "/orders" ? 'active' : undefined}>
                    <BiBox size={26} />
                    <span>Orders</span>
                </a>
                <a href="/messages" className={activePage === "/messages" ? 'active' : undefined}>
                    <BiEnvelope size={26} />
                    <span>Messages</span>
                    {/* <div className="badge">7</div> */}
                </a>
                <a href="/analytics" className={activePage === "/analytics" ? 'active' : undefined}>
                    <BiChart size={26} />
                    <span>Analytics</span>
                </a>
                <a href="/ads-management" className={adsPages.includes(activePage) ? 'active' : undefined}>
                    <BiCreditCard size={26} />
                    <span>Ads</span>
                </a>
                <a href="/brand-ambassadors" className={brandAmbassadorsPages.includes(activePage) ? 'active' : undefined}>
                    <BiGroup size={26} />
                    <span>Creators</span>
                </a>
                <a href="/contact-support" className={activePage === "/contact-support" ? 'active' : undefined}>
                    <BiSupport size={26} />
                    <span>Support</span>
                </a>
                <a className="logout-btn" onClick={logoutUser}>
                    <FaPowerOff size={26} />
                    <span>Logout</span>
                </a>
            </div>
        </div>
    );
};

export default Sidebar;