import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { apiCall } from '../backend/api/apiService';
function AddProduct() {
    const user = useAuthStore(state => state.user);
    const brand_id = user.brand.id;
    const [allowAffiliations, setAllowAffiliations] = useState(true);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productTags, setProductTags] = useState('');
    const [productSKU, setProductSKU] = useState('');
    const [regularPrice, setRegularPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [affiliatePrice, setAffiliatePrice] = useState('');
    const [affiliateCommission, setAffiliateCommission] = useState('');
    const [affiliateRequirement, setAffiliateRequirement] = useState('');
    const [affiliationLink, setAffiliationLink] = useState('');
    const [category, setCategory] = useState('');
    const [newCategory, setNewCategory] = useState('');

    const toggleAffiliations = () => {
        setAllowAffiliations(!allowAffiliations);
    };

    const [mainImage, setMainImage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [mainImageData, setMainImageData] = useState(null);
    const [galleryImagesData, setGalleryImagesData] = useState([]);

    const [categories, setCategories] = useState([]); // Holds the categories at each level
    const [selectedCategories, setSelectedCategories] = useState([]); // Holds selected category IDs at each level
    const [isDigitalProduct, setIsDigitalProduct] = useState(false);

    const toggleDigitalProduct = () => {
        setIsDigitalProduct(!isDigitalProduct);
    };

    const fetchCategories = async (parentId) => {
        // Fetch subcategories based on the parent ID
        const response = await apiCall(`/api/products/categories/${parentId ? 'sub/' + parentId : 'root'}`);
        return response.categories;
    };

    const handleCategoryChange = async (index, categoryId) => {
        // Update the selected categories array at the current level
        const newSelectedCategories = [...selectedCategories];
        newSelectedCategories[index] = categoryId;
        setSelectedCategories(newSelectedCategories);

        // If there are further subcategories, fetch and add them as the next level
        const nextCategories = await fetchCategories(categoryId);
        if (nextCategories.length > 0) {
            const newCategories = [...categories];
            newCategories[index + 1] = nextCategories; // Set the next level categories
            setCategories(newCategories.slice(0, index + 2)); // Truncate deeper levels if any
        } else {
            // No further subcategories, so truncate categories array
            setCategories(categories.slice(0, index + 1));
        }
        console.log('Selected categories:', selectedCategories);
    };

    useEffect(() => {
        // Fetch root categories when component mounts
        const fetchRootCategories = async () => {
            var rootCategories = await fetchCategories(null);
            // filter with digital product category true false
            rootCategories = rootCategories.filter(category => category.digital_product_category === isDigitalProduct);
            setCategories([rootCategories]);
        };
        fetchRootCategories();
    }, [isDigitalProduct]);

    const handleMainImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setMainImage(imageUrl);
            setMainImageData(file);
        }
    };

    const handleGalleryImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const newGalleryImages = [...galleryImages];
            newGalleryImages[index] = URL.createObjectURL(file);
            setGalleryImages(newGalleryImages);
            setGalleryImagesData([...galleryImagesData, file]);
        }
    };


    const navigate = useNavigate();

    const AddProductHandler = async () => {
        let data = new FormData();

        // set category as the last category in the selected categories array
        const category_id = selectedCategories[selectedCategories.length - 1];

        data.append('name', productName);
        data.append('description', productDescription);
        data.append('regular_price', regularPrice);
        data.append('sale_price', salePrice);
        data.append('affiliate_price', affiliatePrice);
        data.append('stock', '50'); // hardcoded stock, can be modified
        data.append('brand_id', brand_id);
        data.append('category_id', category_id); // Adjust if category ID is dynamic
        data.append('product_tags', productTags);
        data.append('product_sku', productSKU);
        data.append('affiliation_commission_percentage', affiliateCommission);
        data.append('affiliation_minimum_requirement', affiliateRequirement);
        data.append('digital_product', isDigitalProduct);
        if (mainImage) data.append('main_image', mainImageData);
        galleryImagesData.forEach((image, index) => data.append('image_gallery', image));

        const response = await apiCall('/api/products/create', 'POST', data);
        console.log('Product added:', response);
        navigate('/shop');
    }

    return (
        <div className='add-product'>
            <div className="add-product-header">
                <div>
                    <h1>Add Product</h1>
                    <p style={{ marginTop: -20 }}>Add product for your customers</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button className="bulk-update-button" style={{ marginLeft: 10 }}>Bulk Upload CSV</button>
                    <button className="save-product-button" onClick={AddProductHandler} style={{ marginLeft: 10 }}>Save Product</button>
                </div>
            </div>
            <div className="product-form">
                <div className="form-section basic-info">
                    <h3>Basic Information</h3>
                    {/* <label htmlFor="product-name">Product Name</label> */}
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Product Name"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10 }}>
                        <label htmlFor="product-name">Digital Product</label>
                        <label className="switch2">
                            <input type="checkbox" checked={isDigitalProduct} onChange={toggleDigitalProduct} />
                            <span className="slider2 round"></span>
                        </label>
                    </div>
                    {/* <label htmlFor="product-description">Product Description</label> */}
                    <div className='product-textarea'>
                        <textarea
                            placeholder="Product Description"
                            value={productDescription}
                            onChange={(e) => setProductDescription(e.target.value)}
                        ></textarea>
                    </div>
                    {/* <label htmlFor="product-tags">Product Tags</label> */}
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Product Tags"
                            value={productTags}
                            onChange={(e) => setProductTags(e.target.value)}
                        />
                    </div>
                    {/* <label htmlFor="product-sku">Product SKU</label> */}
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Product SKU"
                            value={productSKU}
                            onChange={(e) => setProductSKU(e.target.value)}
                        />
                    </div>
                </div>

                <div className="form-section affiliations">
                    <h3>Affiliations</h3>
                    <div style={{ display: 'flex', gap: 10, margin: "10px 0px" }}>
                        <span>Allow Affiliations</span>
                        <label className="switch">
                            <input type="checkbox" checked={allowAffiliations} onChange={toggleAffiliations} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {allowAffiliations && <>
                        {/* <label htmlFor="affiliate-commission">Affiliate Commission</label> */}
                        <div className='product-input'>
                            <input
                                type="text"
                                placeholder="Affiliate Commission %"
                                value={affiliateCommission}
                                onChange={(e) => setAffiliateCommission(e.target.value)}
                                disabled={!allowAffiliations}
                            />
                        </div>
                        {/* <label htmlFor="affiliate-requirement">Affiliate Requirement</label> */}
                        <div className='product-input'>
                            <input
                                type="text"
                                placeholder="Creator Minimum Follower Requirement"
                                value={affiliateRequirement}
                                onChange={(e) => setAffiliateRequirement(e.target.value)}
                                disabled={!allowAffiliations}
                            />
                        </div>
                        {/* <label htmlFor="affiliation-link">Affiliation Link</label> */}
                        <div className='product-input'>
                            <input
                                type="text"
                                placeholder="Affiliation Link"
                                value={affiliationLink}
                                onChange={(e) => setAffiliationLink(e.target.value)}
                                disabled={!allowAffiliations}
                            />
                        </div>
                    </>
                    }
                </div>
                <div className="form-section product-images">
                    <h3>Product Images</h3>
                    <div className="image-upload">
                        <div className="main-image">
                            <span style={{ width: '100%' }}>Main Image</span>
                            <div className="image-placeholder-main">
                                {mainImage ? (
                                    <img src={mainImage} alt="Main" className="uploaded-image" />
                                ) : (
                                    <>
                                        <label htmlFor="main-image-upload" className="image-upload-label" style={{ width: '100%', height: '100%' }}>
                                            <BiPlus size={40} />
                                        </label>
                                        <input
                                            type="file"
                                            id="main-image-upload"
                                            style={{ display: 'none' }}
                                            onChange={handleMainImageChange}
                                            accept="image/*"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="gallery-images">
                            <span style={{ width: '100%' }}>Product Gallery Images</span>
                            {[...Array(4)].map((_, i) => (
                                <div key={i} className="image-placeholder">
                                    {galleryImages[i] ? (
                                        <img src={galleryImages[i]} alt={`Gallery ${i}`} className="uploaded-image" />
                                    ) : (
                                        <>
                                            <label htmlFor={`gallery-image-upload-${i}`} className="image-upload-label" style={{ width: '100%', height: '100%' }}>
                                                <BiPlus size={20} />
                                            </label>
                                            <input
                                                type="file"
                                                id={`gallery-image-upload-${i}`}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleGalleryImageChange(i, e)}
                                                accept="image/*"
                                            />
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <br />
                    <label htmlFor="image-urls">Image URLs</label>
                    <div className='product-textarea image-urls-area'>
                        <textarea placeholder="Image URLs (separated by comma)"></textarea>
                    </div>
                </div>
                <div className="form-section price-section">
                    <h3>Price</h3>
                    {/* <label htmlFor="regular-price">Regular Price</label> */}
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Regular Price"
                            value={regularPrice}
                            onChange={(e) => setRegularPrice(e.target.value)}
                        />
                    </div>
                    {/* <label htmlFor="sale-price">Discount Price</label> */}
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Discount Price"
                            value={salePrice}
                            onChange={(e) => setSalePrice(e.target.value)}
                        />
                    </div>
                    {/* <label htmlFor="affiliate-price">Affiliate Price</label> */}
                    <div className='product-input'>
                        <input
                            type="text"
                            placeholder="Affiliate Price"
                            value={affiliatePrice}
                            onChange={(e) => setAffiliatePrice(e.target.value)}
                        />
                    </div>
                    <label htmlFor="discount-expiry-date">Discount Expiry Date</label>
                    <div className='product-input'>
                        <input type="date" placeholder="Discount Expiry Date" />
                    </div>
                </div>
            </div>

            <div className="form-section">
                <h3>Category</h3>
                {categories.map((catLevel, index) => (
                    <div key={index} className='category-section'>
                        <label htmlFor={`category-level-${index}`} style={{
                            display: 'block',
                            marginTop: 20,
                            fontWeight: 'bold'
                        }}>Category Level {index + 1}</label>
                        <br />
                        <select
                            className='add-category-select'
                            value={selectedCategories[index] || ''}
                            onChange={(e) => handleCategoryChange(index, e.target.value)}
                            style={{ width: '20%', marginLeft: 20 }}
                        >
                            <option value="">Select Category</option>
                            {catLevel.map(category => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default AddProduct