import React, { useEffect, useState } from "react";
import Header from "../Header";
import StatsCard from "../components/StatsCard";
import { BiBox, BiDollarCircle, BiSolidMessageDots } from "react-icons/bi";
import { BsPersonBadge } from "react-icons/bs";
import { FaPeopleGroup } from "react-icons/fa6";
import { AiOutlineProduct } from "react-icons/ai";
import PopularProducts from "../components/PopularProducts";
import PopularCreators from "../components/PopularCreators";
import LoyalCustomers from "../components/LoyalCustomers";
import { useAuthStore } from "../store/authStore";
import { apiCall } from "../backend/api/apiService";
import { UserTrendsChart } from "../components/Charts/UserTrendsChart";
import { WebsiteTrafficChart } from "../components/Charts/WebsiteTrafficCharts";
import { DeviceTrafficChart } from "../components/Charts/DeviceTrafficChart";
import { LocationTrafficChart } from "../components/Charts/LocationTrafficChart";

const Dashboard = () => {
    const user = useAuthStore(state => state.user);
    const brand_id = user.brand.id;

    const [dashboardData, setDashboardData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiCall(`/api/vendorDashboard/header/${brand_id}`, "GET");
            setDashboardData(response.data);
            console.log(response.data);
        };
        fetchData();
    }, []);
    const headerBannerData = { supplierName: dashboardData.supplierName, brandName: dashboardData.brandName }
    return (
        <div className="dashboard">
            {/* <Header data={headerBannerData} /> */}
            <div className="dashboard-summary">
                <StatsCard title="Orders" value={dashboardData.orders} icon={<BiBox size={40} />} />
                <StatsCard title="Revenue" value={dashboardData.currency + dashboardData.revenue} icon={<BiDollarCircle size={40} />} />
                <StatsCard title="Creators" value={dashboardData.affiliatedCreators} icon={<BsPersonBadge size={40} />} />
                <StatsCard title="Customers" value={dashboardData.customers} icon={<FaPeopleGroup size={40} />} />
                <StatsCard title="Products" value={dashboardData.products} icon={<AiOutlineProduct size={40} />} />
                <StatsCard title="Messages" value={dashboardData.unreadMessages} icon={<BiSolidMessageDots size={40} />} />
            </div>
            {/* <div className="dashboard-details">
                <PackageInfo />
                <Map />
            </div>
            <div className="dashboard-stats">
                <SpeedStatistics />
                <Transportations />
            </div> */}
            <div className="dashboard-details">
                <PopularProducts />
            </div>
            {/* <div className="dashboard-details">
                <PopularCreators />
            </div>
            <div className="dashboard-details">
                <LoyalCustomers />
            </div> */}

            <div style={{
                padding: '24px 0 ',
                display: 'flex',
                gap: '30px',
            }}>
                <UserTrendsChart />
                <WebsiteTrafficChart />
            </div>
            <div style={{
                padding: '10px 0px',
                display: 'grid',
                gap: '30px',
                gridTemplateColumns: 'repeat(2, 1fr)',
                backgroundColor: '#f8f9fa'
            }}>
                <DeviceTrafficChart />
                <LocationTrafficChart />
            </div>
        </div>
    );
};

export default Dashboard;