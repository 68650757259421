import React, { useEffect, useState } from 'react'
import { BiChevronLeft, BiChevronRight, BiSearch } from 'react-icons/bi'
// import HawaiianShoes from '../assets/sampleImages/products/hawaiian-shoes.jpg'
// import VitaminCSerum from '../assets/sampleImages/products/vitamin-c-serum.jpg'
// import SmartWatch from '../assets/sampleImages/products/smart-watch.jpg'
// import ChanelN5 from '../assets/sampleImages/products/chanel-n5.jpg'
// import PressedOrangeJuice from '../assets/sampleImages/products/pressed-orange-juice.jpg'
// import WirelessHeadphones from '../assets/sampleImages/products/wireless-headphones.jpg'
import { Link } from 'react-router-dom'

const orders = [
    {
        orderId: 'ORD1234',
        customerName: 'John Doe',
        numOfItems: 3,
        totalPrice: 299.99,
        orderStatus: 'Pending',
        orderDate: '2024-09-17',
        customerImage: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    {
        orderId: 'ORD5678',
        customerName: 'Jane Smith',
        numOfItems: 2,
        totalPrice: 99.99,
        orderStatus: 'Confirmed',
        orderDate: '2024-09-15',
        customerImage: 'https://randomuser.me/api/portraits/women/2.jpg'
    },
    {
        orderId: 'ORD9876',
        customerName: 'Alice Johnson',
        numOfItems: 5,
        totalPrice: 459.99,
        orderStatus: 'Shipped',
        orderDate: '2024-09-12',
        customerImage: 'https://randomuser.me/api/portraits/women/3.jpg'
    },
    {
        orderId: 'ORD5432',
        customerName: 'Bob Brown',
        numOfItems: 1,
        totalPrice: 59.99,
        orderStatus: 'Out for Delivery',
        orderDate: '2024-09-10',
        customerImage: 'https://randomuser.me/api/portraits/men/4.jpg'
    },
    {
        orderId: 'ORD4321',
        customerName: 'Chris Green',
        numOfItems: 4,
        totalPrice: 210.50,
        orderStatus: 'Delivery',
        orderDate: '2024-09-05',
        customerImage: 'https://randomuser.me/api/portraits/men/5.jpg'
    },
    {
        orderId: 'ORD8765',
        customerName: 'Eve White',
        numOfItems: 2,
        totalPrice: 135.75,
        orderStatus: 'Completed',
        orderDate: '2024-09-02',
        customerImage: 'https://randomuser.me/api/portraits/women/6.jpg'
    },
    {
        orderId: 'ORD6543',
        customerName: 'Steve Miller',
        numOfItems: 6,
        totalPrice: 524.99,
        orderStatus: 'Cancelled',
        orderDate: '2024-08-28',
        customerImage: 'https://randomuser.me/api/portraits/men/7.jpg'
    },
    {
        orderId: 'ORD3456',
        customerName: 'Sarah Brown',
        numOfItems: 3,
        totalPrice: 149.89,
        orderStatus: 'Failed',
        orderDate: '2024-08-22',
        customerImage: 'https://randomuser.me/api/portraits/women/8.jpg'
    },
    {
        orderId: 'ORD6789',
        customerName: 'Michael Scott',
        numOfItems: 8,
        totalPrice: 679.49,
        orderStatus: 'Returned',
        orderDate: '2024-08-20',
        customerImage: 'https://randomuser.me/api/portraits/men/9.jpg'
    },
    {
        orderId: 'ORD8763',
        customerName: 'Angela Davis',
        numOfItems: 4,
        totalPrice: 275.99,
        orderStatus: 'Refunded',
        orderDate: '2024-08-15',
        customerImage: 'https://randomuser.me/api/portraits/women/10.jpg'
    },
    {
        orderId: 'ORD1236',
        customerName: 'Thomas Anderson',
        numOfItems: 1,
        totalPrice: 79.99,
        orderStatus: 'Partially Shipped',
        orderDate: '2024-08-12',
        customerImage: 'https://randomuser.me/api/portraits/men/11.jpg'
    },
    {
        orderId: 'ORD7890',
        customerName: 'Nancy Carter',
        numOfItems: 7,
        totalPrice: 549.75,
        orderStatus: 'Partially Refunded',
        orderDate: '2024-08-05',
        customerImage: 'https://randomuser.me/api/portraits/women/12.jpg'
    },
    {
        orderId: 'ORD5670',
        customerName: 'George Thompson',
        numOfItems: 2,
        totalPrice: 150.00,
        orderStatus: 'Awaiting Pickup',
        orderDate: '2024-08-02',
        customerImage: 'https://randomuser.me/api/portraits/men/13.jpg'
    },
    {
        orderId: 'ORD2345',
        customerName: 'Laura King',
        numOfItems: 5,
        totalPrice: 499.99,
        orderStatus: 'Delivered',
        orderDate: '2024-07-30',
        customerImage: 'https://randomuser.me/api/portraits/women/14.jpg'
    },
    {
        orderId: 'ORD7654',
        customerName: 'James Stewart',
        numOfItems: 3,
        totalPrice: 199.95,
        orderStatus: 'Shipped',
        orderDate: '2024-07-28',
        customerImage: 'https://randomuser.me/api/portraits/men/15.jpg'
    },
    {
        orderId: 'ORD5436',
        customerName: 'Samantha White',
        numOfItems: 2,
        totalPrice: 95.75,
        orderStatus: 'Pending',
        orderDate: '2024-07-25',
        customerImage: 'https://randomuser.me/api/portraits/women/16.jpg'
    },
    {
        orderId: 'ORD2346',
        customerName: 'Daniel Harris',
        numOfItems: 4,
        totalPrice: 345.80,
        orderStatus: 'Delivered',
        orderDate: '2024-07-22',
        customerImage: 'https://randomuser.me/api/portraits/men/17.jpg'
    },
    {
        orderId: 'ORD8976',
        customerName: 'Julia Moore',
        numOfItems: 1,
        totalPrice: 55.99,
        orderStatus: 'Shipped',
        orderDate: '2024-07-18',
        customerImage: 'https://randomuser.me/api/portraits/women/18.jpg'
    },
    {
        orderId: 'ORD5437',
        customerName: 'Patrick Allen',
        numOfItems: 6,
        totalPrice: 600.99,
        orderStatus: 'Shipped',
        orderDate: '2024-07-10',
        customerImage: 'https://randomuser.me/api/portraits/men/19.jpg'
    },
    {
        orderId: 'ORD3219',
        customerName: 'Kate Lewis',
        numOfItems: 2,
        totalPrice: 89.99,
        orderStatus: 'Delivered',
        orderDate: '2024-07-05',
        customerImage: 'https://randomuser.me/api/portraits/women/20.jpg'
    },
    {
        orderId: 'ORD4327',
        customerName: 'Henry Adams',
        numOfItems: 3,
        totalPrice: 275.00,
        orderStatus: 'Pending',
        orderDate: '2024-07-02',
        customerImage: 'https://randomuser.me/api/portraits/men/21.jpg'
    },
    {
        orderId: 'ORD8761',
        customerName: 'Victoria Young',
        numOfItems: 4,
        totalPrice: 325.99,
        orderStatus: 'Delivered',
        orderDate: '2024-06-28',
        customerImage: 'https://randomuser.me/api/portraits/women/22.jpg'
    },
    {
        orderId: 'ORD6529',
        customerName: 'Paul Wright',
        numOfItems: 1,
        totalPrice: 49.99,
        orderStatus: 'Shipped',
        orderDate: '2024-06-24',
        customerImage: 'https://randomuser.me/api/portraits/men/23.jpg'
    },
    {
        orderId: 'ORD2198',
        customerName: 'Rachel Wilson',
        numOfItems: 5,
        totalPrice: 400.00,
        orderStatus: 'Shipped',
        orderDate: '2024-06-20',
        customerImage: 'https://randomuser.me/api/portraits/women/24.jpg'
    }
];


export default function Orders() {
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage, setOrdersPerPage] = useState(10); // Default is 3 orders per page
    const [selectedStatus, setSelectedStatus] = useState('');  // Filter state

    // Calculate the indexes of orders for the current page
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const [filteredOrders, setFilteredOrders] = useState(orders.slice(indexOfFirstOrder, indexOfLastOrder));

    const nextPage = () => {
        if (currentPage < Math.ceil(orders.length / ordersPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const filterOrders = () => {
        if (selectedStatus === '') {
            setFilteredOrders(orders); // If no status is selected, show all orders
        } else {
            const filtered = orders.filter(order => order.orderStatus === selectedStatus);
            setFilteredOrders(filtered); // Update with filtered orders
        }
    };


    const [search, setSearch] = useState('');

    useEffect(() => {
        filterOrders();
    }, [selectedStatus]);

    useEffect(() => {
        const filteredOrdersTemp = orders.filter(order =>
            order.customerName.toLowerCase().includes(search.toLowerCase()) ||
            order.orderId.toLowerCase().includes(search.toLowerCase())
        );
        const slicedFilters = filteredOrdersTemp.slice(indexOfFirstOrder, indexOfLastOrder);
        setFilteredOrders(slicedFilters);
    }, [search, ordersPerPage, currentPage]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'Pending':
                return '#ffc107';  // Yellow color for pending
            case 'Confirmed':
                return '#17a2b8';  // Teal color for confirmed
            case 'Shipped':
                return '#007bff';  // Blue color for shipped
            case 'Out for Delivery':
                return '#6610f2';  // Purple color for out for delivery
            case 'Delivered':
                return '#28a745';  // Green color for delivered
            case 'Completed':
                return '#20c997';  // Light green color for completed
            case 'Cancelled':
                return '#dc3545';  // Red color for cancelled
            case 'Failed':
                return '#e83e8c';  // Pink color for failed
            case 'Returned':
                return '#fd7e14';  // Orange color for returned
            case 'Refunded':
                return '#6f42c1';  // Violet color for refunded
            case 'Partially Shipped':
                return '#343a40';  // Dark gray color for partially shipped
            case 'Partially Refunded':
                return '#adb5bd';  // Light gray color for partially refunded
            case 'Awaiting Pickup':
                return '#ffc107';  // Yellow color for awaiting pickup
            default:
                return '#6c757d';  // Gray color for other statuses
        }
    };

    const statusActionText = (status) => {
        switch (status) {
            case 'Pending':
                return 'Accept';  // Action for pending
            case 'Confirmed':
                return 'Fulfill';  // Action for confirmed
            case 'Shipped':
                return 'Status';  // Action for shipped
            case 'Out for Delivery':
                return 'Agent Details';  // Action for out for delivery
            case 'Delivered':
                return 'Feedback';  // Action for delivered
            case 'Completed':
                return 'Payment';  // Action for completed
            case 'Cancelled':
                return 'Reason';  // Action for cancelled
            case 'Failed':
                return 'Reason';  // Action for failed
            case 'Returned':
                return 'Reship';  // Action for returned
            case 'Refunded':
                return 'Check PID';  // Action for refunded
            case 'Partially Shipped':
                return 'Status';  // Action for partially shipped
            case 'Partially Refunded':
                return 'Check PID';  // Action for partially refunded
            case 'Awaiting Pickup':
                return 'Contact Agent';  // Action for awaiting pickup
            default:
                return 'No Action';  // Default action for unknown statuses
        }
    };


    return (
        <div className="shop">
            <div className='shop-header'>
                <div className="shop-container">
                    <h1>Orders</h1>
                    <div className="shop-search">
                        <BiSearch color='black' size={30} />
                        <input type="text" placeholder="Search Orders by Customer or Order ID"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </div>
                </div>
                <div className="shop-container">
                    <div className="products-per-page">
                        <label htmlFor="ordersPerPage">Orders per page: </label>
                        <select
                            id="ordersPerPage"
                            onChange={(e) => setOrdersPerPage(Number(e.target.value))}
                            value={ordersPerPage}
                        >
                            <option value={3}>3</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                        </select>
                    </div>
                    {/* <button className="filter-button">
                        <BiFilter color='blue' size={30} />
                        Filter
                    </button> */}
                    <div className="filter-section">
                        <select
                            id="orderStatusFilter"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="">All Orders</option>
                            <option value="Pending">Pending</option>
                            <option value="Confirmed">Confirmed</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Out for Delivery">Out for Delivery</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Failed">Failed</option>
                            <option value="Returned">Returned</option>
                            <option value="Refunded">Refunded</option>
                            <option value="Partially Shipped">Partially Shipped</option>
                            <option value="Partially Refunded">Partially Refunded</option>
                            <option value="Awaiting Pickup">Awaiting Pickup</option>

                        </select>
                        {/* <button className="filter-button" onClick={filterOrders}>
                            <BiFilter color='blue' size={30} />
                            Filter
                        </button> */}
                    </div>



                    <Link to={"/orders/add-order"}
                        style={{ color: 'white', textDecoration: 'none' }}
                        className="add-order-button"
                    >
                        Add Order
                    </Link>
                </div>
            </div>
            <div className="table-container">
                <table className="product-table">
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Customer</th>
                            <th>Items</th>
                            <th>Total Price</th>
                            <th>Status</th>
                            <th>Order Date</th>
                            <th>Status Actions</th>
                            {/* <th>Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredOrders.map((order, index) => (
                                <tr key={order.orderId} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8' }}>
                                    <td>{order.orderId}</td>
                                    <td>
                                        <img src={order.customerImage} alt="product" className='orders-profile-picture' />
                                        {order.customerName}
                                    </td>
                                    <td>{order.numOfItems}</td>
                                    <td>${order.totalPrice.toFixed(2)}</td>
                                    <td><div className="order-item">
                                        <div
                                            className="order-status"
                                            style={{
                                                border: `3px solid ${getStatusColor(order.orderStatus)}`,
                                                backgroundColor: '#fff',
                                                padding: '10px',
                                                width: 100,
                                                textAlign: 'center',
                                                borderRadius: 15,
                                                width: 150,
                                                display: 'inline-block'
                                            }}
                                        >
                                            {order.orderStatus}
                                        </div>
                                    </div></td>
                                    <td>{order.orderDate}</td>
                                    <td>
                                        <button className='product-table-view-btn status-action'>{statusActionText(order.orderStatus)}</button>
                                    </td>
                                    {/* <td>
                                        <button className='product-table-edit-btn'>Edit</button>
                                    </td> */}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <div className="pagination">
                    <span>
                        Showing {indexOfFirstOrder + 1} - {Math.min(indexOfLastOrder, orders.length)} of {orders.length} Orders
                    </span>
                    <div className="pagination-controls">
                        <button className="prev-btn" onClick={prevPage} disabled={currentPage === 1}>
                            <BiChevronLeft size={40} color='black' />
                        </button>
                        <span>{currentPage} / {Math.ceil(orders.length / ordersPerPage)}</span>
                        <button className="next-btn" onClick={nextPage} disabled={currentPage === Math.ceil(orders.length / ordersPerPage)}>
                            <BiChevronRight size={40} color='black' />
                        </button>
                    </div>
                </div>
            </div>

        </div>

    )
}