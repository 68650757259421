// Import the necessary Firebase services
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
// Firebase configuration

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const registerServiceWorker = async () => {
  const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');

  // Pass config data to service worker via postMessage
  registration.active.postMessage({
    firebaseConfig: firebaseConfig
  });

  return registration;
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const authInstance = getAuth(app);
// const firestoreInstance = getFirestore(app);
// const analyticsInstance = getAnalytics(app);
// const storageInstance = getStorage(app);

// Export instances for use in other parts of the app
// export { authInstance, firestoreInstance, analyticsInstance, storageInstance };
export { authInstance };
export default app;
