import React, { useState } from "react";
import "../styles/ChatMessages.css";
import { TiTick } from "react-icons/ti";
import { IoCheckmarkCircle } from "react-icons/io5";
import { BiBell, BiSend } from "react-icons/bi";

// Sample JSON Data - You can use this as your initial chat data
const initialChats = [
    {
        id: 1,
        name: 'Alice Whitman',
        messages: [
            { sender: 'Alice', content: 'Here are all the files. Let me know once you\'ve had a look.', time: '14:03' },
            { sender: 'Me', content: 'So beautiful here!', time: '13:56' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/women/1.jpg',
        creator: 'false',
        online: 'true',
    },
    {
        id: 2,
        name: 'John',
        messages: [
            { sender: 'John', content: 'Dinner last night?', time: '20:18' },
            { sender: 'Me', content: 'It was amazing! We should do it again.', time: '20:21' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/men/2.jpg',
        creator: 'true',
        online: 'false',
    },
    {
        id: 3,
        name: 'Bob Smith',
        messages: [
            { sender: 'Bob', content: 'Are we still on for the game this Friday?', time: '10:02' },
            { sender: 'Me', content: 'Absolutely! Can’t wait!', time: '10:05' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/men/3.jpg',
        creator: 'true',
        online: 'true',
    },
    {
        id: 4,
        name: 'Catherine Johnson',
        messages: [
            { sender: 'Catherine', content: 'Finished the report! Need your feedback.', time: '16:45' },
            { sender: 'Me', content: 'I’ll check it out tonight!', time: '16:50' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/women/2.jpg',
        creator: 'true',
        online: 'false',
    },
    {
        id: 5,
        name: 'Sara',
        messages: [
            { sender: 'Sara', content: 'What did you think of the latest book?', time: '19:30' },
            { sender: 'Me', content: 'I loved it! So many twists!', time: '19:32' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/women/3.jpg',
        creator: 'false',
        online: 'true',
    },
    {
        id: 6,
        name: 'David',
        messages: [
            { sender: 'David', content: 'Let’s finalize the presentation by tomorrow.', time: '09:10' },
            { sender: 'Me', content: 'I’ll have my part ready!', time: '09:15' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/men/4.jpg',
        creator: 'true',
        online: 'false',
    },
    {
        id: 7,
        name: 'Grandma Betty',
        messages: [
            { sender: 'Grandma', content: 'Don’t forget to come for dinner this Sunday!', time: '15:00' },
            { sender: 'Me', content: 'Wouldn’t miss it for the world!', time: '15:05' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/women/4.jpg',
        creator: 'false',
        online: 'false',
    },
    {
        id: 8,
        name: 'Tina',
        messages: [
            { sender: 'Tina', content: 'Just booked my flight to Paris!', time: '12:30' },
            { sender: 'Me', content: 'That’s awesome! We should meet up there!', time: '12:35' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/men/5.jpg',
        creator: 'true',
        online: 'true',
    },
    {
        id: 9,
        name: 'Jenny',
        messages: [
            { sender: 'Jenny', content: 'How’s your fitness routine going?', time: '17:20' },
            { sender: 'Me', content: 'Pretty good! Just finished a yoga session.', time: '17:25' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/women/5.jpg',
        creator: 'false',
        online: 'true',
    },
    {
        id: 10,
        name: 'Leo',
        messages: [
            { sender: 'Leo', content: 'Ready for the tournament tomorrow?', time: '22:01' },
            { sender: 'Me', content: 'Absolutely! Let’s bring our A-game.', time: '22:03' },
        ],
        profilePicture: 'https://randomuser.me/api/portraits/men/6.jpg',
        creator: 'true',
        online: 'false',
    },
];

function ChatHeader({ currentChat }) {
    return (
        <div className="chat-header">
            <img
                src={currentChat.profilePicture}
                alt={`${currentChat.name}'s avatar`}
                className="profile-picture"
            />
            <h2>{currentChat.name}</h2>
            <span
                style={{
                    color: currentChat.online == 'true' ? "green" : "red",
                }}
            >{currentChat.online == 'true' ? "Online" : "Offline"}</span>
        </div>
    );
}

function ChatInput({ onSendMessage }) {
    const [message, setMessage] = useState("");

    const handleSend = (e) => {
        e.preventDefault();
        if (message.trim()) {
            onSendMessage(message.trim());
            setMessage("");
        }
    };

    return (
        <div className="chat-input">
            <form onSubmit={handleSend}>
                <input
                    type="text"
                    value={message}
                    placeholder="Type a message"
                    onChange={(e) => setMessage(e.target.value)}
                />
            </form>
            {/* send message icon */}
            <button onClick={handleSend}>
                <BiSend size={20} />
            </button>
        </div>
    );
}

function ChatWindow({ currentChat, onSendMessage }) {
    return (
        <div className="chat-window">
            <ChatHeader currentChat={currentChat} />
            <div className="chat-content">
                {currentChat.messages.map((message, idx) => (
                    <div
                        key={idx}
                        className={`message ${message.sender === "Me" ? "sender" : "receiver"}`}
                    >
                        <span className="message-content">{message.content}</span>
                        <span className="message-time">{message.time}</span>
                    </div>
                ))}
            </div>
            <ChatInput onSendMessage={onSendMessage} />
        </div>
    );
}

function Sidebar({ chats, onSelectChat, selectedChatId, searchQuery, setSearchQuery }) {
    return (
        <div className="sidebar-chat-container">
            <div className="sidebar-header">
                <div style={{ display: "flex", }}>
                    <h2>Chats</h2>
                    <div className="notification-badge">
                        <BiBell size={20} />
                    </div>


                </div>
                <input
                    type="text"
                    placeholder="Search or start new chat"
                    value={searchQuery} // Use the searchQuery state
                    onChange={(e) => setSearchQuery(e.target.value)} // Update the search query when input changes
                />
            </div>
            <div className="sidebar-chats">
                {chats.map((chat) => (
                    <div
                        key={chat.id}
                        className={`sidebar-chat ${chat.id === selectedChatId ? 'active' : ''}`}
                        onClick={() => onSelectChat(chat.id)}
                    >
                        <div style={{ position: 'relative' }} className="profile-picture">
                            <img
                                src={chat.profilePicture}
                                alt={`${chat.name}'s avatar`}
                                className="profile-picture"
                            />
                            {/* online circle */}
                            <div className="online-circle" style={{ backgroundColor: chat.online == 'true' ? "green" : "red" }}></div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', }}>
                                <h3>{chat.name}</h3>
                                {chat.creator === 'true' ?
                                    <IoCheckmarkCircle color="#000" size={18} style={{
                                        marginLeft: '5px',
                                    }} />
                                    : null}
                            </div>
                            <p>{chat.messages[chat.messages.length - 1].sender}: {chat.messages[chat.messages.length - 1].content}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function ChatMessages() {
    const [chats, setChats] = useState(initialChats);
    const [selectedChatId, setSelectedChatId] = useState(chats[0].id); // Default to first chat
    const [searchQuery, setSearchQuery] = useState(''); // State for search query

    const currentChat = chats.find((chat) => chat.id === selectedChatId);

    const handleSendMessage = (messageText) => {
        const updatedChats = chats.map((chat) => {
            if (chat.id === selectedChatId) {
                const updatedMessages = [
                    ...chat.messages,
                    { sender: "Me", content: messageText, time: new Date().toLocaleTimeString().slice(0, 5) },
                ];
                return { ...chat, messages: updatedMessages };
            }
            return chat;
        });
        setChats(updatedChats);
    };

    // Filter chats based on the search query
    const filteredChats = chats.filter((chat) =>
        chat.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="messages">
            <Sidebar
                chats={filteredChats} // Use filteredChats instead of all chats
                selectedChatId={selectedChatId}
                onSelectChat={setSelectedChatId}
                searchQuery={searchQuery} // Pass searchQuery
                setSearchQuery={setSearchQuery} // Pass setSearchQuery method
            />
            <ChatWindow currentChat={currentChat} onSendMessage={handleSendMessage} />
        </div>
    );
}
export default ChatMessages;